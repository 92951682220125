<template>
  <div>
    <b-modal id="modalPendingRequest"
      size="xl"
      scrollable
      no-close-on-esc
      no-close-on-backdrop
      dialog-class="modal-dialog-scrollable"
      :title="$t('Solicitudes pendientes')"
      hide-footer
      @close="cleanFilter">
        <div class="mt-0">
          <form-render ref="formRenderPendingRequest" 
            class="my-2"
            :fields="fields"
            :form.sync="filters"
            :buttonSend="buttonSend" 
            :containerButtonsClass="containerButtonsClass"
            @send="filterForm">
              <template #buttons>
                <b-button variant="outline-light" v-b-tooltip.hover :title="$t('Limpiar filtros')" class="ml-2" @click="cleanFilter"><feather-icon icon="RefreshCwIcon"/></b-button>
              </template>
          </form-render>

          <table-render id="pending-request" class="mb-2" :rows="rows" :schema="schema" :loading="loading.table">
            <!-- Compañia -->
            <template #company="scope">
              <span>{{ scope.rowdata.company.name }} - <strong>{{ scope.rowdata.company.id }}</strong></span>
            </template>
            <!-- Usuario -->
            <template #creator_user="scope">
              <span>
                <strong>
                  {{ scope.rowdata.last_request.user.first_name }} {{ scope.rowdata.last_request.user.last_name }}
                </strong> 
                - {{ scope.rowdata.last_request.user.email }}
              </span>
            </template>
            <!-- Tipo -->
            <template #type="scope">
              <span>{{ setTypeRequest(scope.rowdata.last_request.type) }}</span>
            </template>
            <!-- Estado -->
            <template #status="scope">
              <span>{{ getRequestsStatuses(scope.rowdata.last_request.status) }}</span>
            </template>

            <!-- Acciones -->
            <template #request_actions="scope">
              <div class="text-center">
                <!-- Ver detalle de retiro -->
                <a class="mr-1 text-primary" v-if="scope.rowdata.last_request.type === 'create'" @click="showPickupDetails(scope.rowdata)" v-b-tooltip.hover :title="$t('Ver detalles')"><feather-icon icon="EyeIcon"/></a>
                <!-- Aprobar solicitud de retiro -->
                <a class="mr-1 text-success" v-if="scope.rowdata.last_request.type === 'create'" @click="showModalActionsRequest(scope.rowdata, 'approve')" v-b-tooltip.hover :title="$t('Aprobar solicitud')"><feather-icon icon="CheckIcon"/></a>
                <!-- Aprobar solicitud de edicion de retiro -->
                <a class="mr-1 text-primary" v-if="scope.rowdata.last_request.type === 'update'" @click="showModalUpdateRequest(scope.rowdata, 'update')" v-b-tooltip.hover :title="$t('Aprobar edición')"><feather-icon icon="Edit2Icon"/></a>
                <!-- Rechazar solicitud de solicitudes -->
                <a class="mr-1 text-danger" @click="showModalActionsRequest(scope.rowdata, 'reject')" v-b-tooltip.hover :title="$t('Rechazar solicitud')"><feather-icon icon="XIcon"/></a>
              </div>
            </template>
          </table-render>
        </div>
    </b-modal>
    
    <!-- Modal aprobar o rechazar un retiro frecuente -->
    <modal-approve-or-reject-frequent :currentRequest="currentRequest" @updatePendingRequest="cleanFilter"></modal-approve-or-reject-frequent>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TabPickupsService from '../tabPickups.service'
import ModalApproveOrRejectFrequent from './ModalApproveOrRejectFrequent.vue'
export default {
  props: ['updatePendingList'],
  components: { ModalApproveOrRejectFrequent },
  data () {
    return {
      fields: [],
      filters: {},
      permissions: {
        is_team_pickup: false,
        frequent_marketplace_supervisor: false
      },
      ROLES : {
        marketplace: 'marketplace',
        ecommerce: 'ecommerce',
        seller: 'seller',
        admin: 'admin',
        superadmin: 'superadmin'
      },
      currentRequest: {},
      buttonSend: { icon: 'SearchIcon', color: 'warning', title: 'Filtrar' },
      containerButtonsClass: 'col-sm-12 col-md-4 container-button mt-2',
      rows: [],
      schema: [],
      loading: {
        table: false
      },
      tabPickupsService: new TabPickupsService(this)
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession'
    })
  },
  watch: {
    mySession () {
      if (this.mySession.id) this.setInitialData()
    },
    updatePendingList () {
      if (this.permissions.frequent_marketplace_supervisor || this.mySession.role === this.ROLES.admin) this.cleanFilter()
    }
  },
  mounted () {
    this.getAllInitialData()
  },
  methods: {
    getAllInitialData () {
      if (this.mySession.id) this.setInitialData()
    },
    setInitialData () {
      this.loading.table = false
      this.fields = [
        {
          fieldType: 'FieldInput', 
          name: 'identifier',
          type: 'number',
          label: 'N° de retiro',
          clearable: true,
          containerClass: 'col-sm-12 container-info col-md-2'
        },
        { 
          fieldType: 'FieldDatepicker', 
          label: 'Fecha de inicio', 
          name: 'date', 
          range: true,
          containerClass: 'col-sm-12 container-info col-md-2'
        },
        {
          fieldType: 'FieldSelect',
          clearable: true,
          label: 'País',
          name: 'country',
          containerClass: 'col-sm-12 container-info col-md-2',
          addFlags: true
        },
        {
          fieldType: 'FieldSelect', 
          clearable: true, 
          label: 'Courier', 
          name: 'carrier', 
          containerClass: 'col-sm-12 container-info col-md-2', 
          searchOnType: { 
            fx: this.getCarriers, 
            nChars: 3, 
            debounce: 300, 
            allowSearchAsValue: true, 
            persistSearch: true 
          }
        }
      ]
      this.schema = [
        { label: 'Seller - ID', key: 'company', useSlot: true },
        { label: 'Courier', key: 'carrier.name' },
        { label: 'N° de retiro', key: 'identifier' },
        { label: 'Usuario solicitante', key: 'creator_user', useSlot: true },
        { label: 'Fecha', key: 'last_request.date' },
        { label: 'Fecha inicio programa', key: 'last_request.effective_date' },
        { label: 'Tipo', key: 'type', useSlot: true },
        { label: 'Estado', key: 'status', useSlot: true }
      ]
      this.setAcctions()
      if (this.permissions.frequent_marketplace_supervisor || this.mySession.role === this.ROLES.admin) this.getPendingRequests()

      if (this.mySession.role === this.ROLES.admin) this.searchCountry()
      else this.fields = this.fields.filter(field => !['country', 'carrier'].includes(field.name))
    },
    setAcctions() {   
      this.permissions.is_team_pickup = this.tabPickupsService.conditionsByPermissions(this.mySession).is_team_pickup
      this.permissions.frequent_marketplace_supervisor = this.tabPickupsService.conditionsByPermissions(this.mySession).frequent_marketplace_supervisor

      // Si el usuario pertenece al equipo de retiro o es supervisor de mkp se le mostrara la columna de acciones
      if (this.permissions.is_team_pickup || this.permissions.frequent_marketplace_supervisor) {
        this.schema.push({ label: 'Acciones', key: 'request_actions', class: 'text-center', useSlot: true })
      }
    },
    searchCountry () {
      return this.tabPickupsService.callService('getCountries', null, null)
        .then(response => {
          let options = response.data.map(el => ({...el, text: `${el.name1 ? el.name1 : el.name}`}))
          const index = this.fields.findIndex(el => el.name === 'country')
          // Sacamos del array Argentina, Ecuador, EEUU y Uruguay
          options = options.filter(country => !['AR', 'EC', 'US', 'UY'].includes(country.code))
          this.fields[index].options = options
        })
        .catch(error => console.error(error))
    },
    getCarriers(value) {
      const queryParams = {
        name: value
      }
      return this.tabPickupsService.callService('getCarriers', queryParams)
        .then(response => response.data.map(el => ({ ...el, text: `${el.code} - ${el.name}`, id: el.code })))
        .catch(error => console.error(error))
    },
    showModalActionsRequest (pickup, action) {
      // El action indica si es aprobación y rechazo
      pickup.action = action
      this.currentRequest = JSON.stringify(pickup)
      this.$bvModal.show('modalActionsRequest')
      this.$bvModal.hide('modalPendingRequest')
    },
    showModalUpdateRequest (pickup, action) {
      pickup.action = action
      this.$bvModal.hide('modalPendingRequest')
      this.$emit('showModalUpdateRequest', pickup)
    },
    /**
     * Función para obtener las solicitudes pendientes 
     */
    getPendingRequests() {
      this.loading.table = true
      const queryParams = {
        ...this.filters
      }
      if (this.permissions.frequent_marketplace_supervisor || this.mySession.role === this.ROLES.marketplace) {
        queryParams.marketplace_id = this.mySession.organization.id
        queryParams.status_code = 'pending_marketplace'
      }

      this.tabPickupsService.callService('getPendingRequests', queryParams)
        .then(resp => {
          this.rows = resp.data
          this.$emit('updateCountPendingRequest', this.rows)
        })
        .catch(err => console.error(err))
        .finally(err => this.loading.table = false)
    },
    setTypeRequest (type) {
      const requestsTypes = {
        create: 'Creación',
        update: 'Edición',
        disable: 'Desactivación'
      }
      return requestsTypes[type]
    },
    getRequestsStatuses (status) {
      const requestsStatuses = {
        created: 'Creada',
        approved: 'Aprobada',
        rejected: 'Rechazada',
        pending_marketplace: 'Pendiente marketplace',
        rejected_marketplace: 'Rechazado marketplace'
      }
      return requestsStatuses[status]
    },
    filterForm (form) {
      this.filters = {}
      if (form.identifier) this.filters['identifier'] = Number(form.identifier)
      if (form.date) {
        this.filters['effective_date_from'] = this.$options.filters.moment(form.date.start, 'YYYY-MM-DD')
        this.filters['effective_date_to'] = this.$options.filters.moment(form.date.end, 'YYYY-MM-DD')
      }
      if (form.country) this.filters['country_code'] = form.country.code.toLowerCase()
      if (form.carrier) this.filters['carrier_code'] = form.carrier.code

      this.getPendingRequests()
    },
    cleanFilter () {
      this.filters = {}
      this.filterForm(this.filters)
    },
    showPickupDetails (pickup) {
      this.$bvModal.hide('modalPendingRequest')
      pickup.canReturnToList = true
      this.$emit('show-details', pickup)
    },
    showModal (value) {
      this.filters = { identifier: value }
      this.rows = []
      this.getPendingRequests()
      this.$bvModal.show('modalPendingRequest')
    }
  }
}
</script>

<style>

</style>