<template>
    <div class="col-md-12">
      <!-- Formualrio -->
      <b-skeleton v-if="loading.first" type="input" class="mt-2 mb-2 spacing-label-field" height="40px"/>
      <form-render class="mb-2" 
        v-if="!loading.first"
        :fields="fields" 
        :form.sync="formFilter" 
        :buttonSend="buttonSend" 
        @send="filterForm" 
        ref="formRender"
        :containerButtonsClass="containerButtonsClass">
          <template #buttons>
            <!-- <b-button variant="outline-light" v-b-tooltip.hover :title="$t('Limpiar filtros')" class="ml-2" @click="cleanFilter"><feather-icon icon="RefreshCwIcon"/></b-button> 
            <b-button class="search-advanced" variant="link" @click="openAdvancedFiltersModal">{{ $t('advanced-search') }}</b-button>  -->
            <b-dropdown class="mx-1" v-b-tooltip.hover :title="$t('Más opciones')" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="light"> 
              <template #button-content><feather-icon icon="SettingsIcon"/></template>
              <b-dropdown-item @click="cleanFilter">{{$t('Limpiar filtros')}}</b-dropdown-item>
              <b-dropdown-item @click="openAdvancedFiltersModal">{{$t('Búsqueda avanzada')}}</b-dropdown-item>
            </b-dropdown>
            <div class="push-right contact-info-buttons">
              <a class="lnk lnk-primary" v-b-modal.infoChannelModalID>
                Canales de comunicación <feather-icon icon="PhoneCallIcon" size="16"/>
              </a>
              <a class="lnk lnk-primary" v-b-modal.CuttingScheduleModalID>
                Horarios de corte <feather-icon icon="TruckIcon" size="16"/>
              </a>
            </div>
          </template>
      </form-render>
      <modal-advanced-pickup-filters :currentTab="currentTab" :form.sync="formFilter" :clearFilters="clearModalFilters" @updateAdvancedFilters="updateAdvancedFilters"></modal-advanced-pickup-filters>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import * as moment from 'moment'
import ModalAdvancedPickupFilters from './ModalAdvancedPickupFilters.vue'
export default {
  components: { ModalAdvancedPickupFilters },
  props: ['currentTab', 'evaluateFilters'],
  data() {
    return {
      fields: [],
      formFilter: {},
      containerButtonsClass: 'col-sm-12 col-md-8 container-button mt-2',
      filters: {},
      advancedFilters: {},
      clearModalFilters: 0,
      loading: {
        first: true
      },
      buttonSend: { icon: 'SearchIcon', color: 'warning', title: 'Filtrar' },
      initialDateRange: {
        start: moment().add(-15, 'days').toDate(),
        end: moment().add(3, 'days').toDate()
      }
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession'
    })
  },
  watch: {
    mySession () {
      if (this.mySession.id) this.setInitialData()
    },
    evaluateFilters: {
      handler(filters) {
        if (filters.need_evaluation) {
          this.formFilter = {
            ...this.formFilter,
            evaluation_status: {
              text: 'Retiros pendientes de evaluación',
              id: 'need_evaluation'
            },
            normal_pickup_type: [
              {
                text: 'Retiro diario',
                code: 'normal',
                id: 1
              }
            ]
          }
        } else {
          this.formFilter = {
            ...this.formFilter,
            evaluation_status: [],
            normal_pickup_type: [
              {
                text: 'Retiro diario',
                code: 'normal',
                id: 1
              },
              {
                text: 'Retiro diario asociado a frecuente',
                code: 'normal_frequent',
                id: 2
              }
            ]
          }
        }
      },
      deep: true
    }
  },
  mounted () {
    this.getAllInitialData()
  },
  methods: {
    getAllInitialData () {
      if (this.mySession.id) this.setInitialData()
    },
    setInitialData () {
      this.loading.first = false
      this.fields = [
        { 
          fieldType: 'FieldDatepicker', 
          label: 'Fecha de búsqueda', 
          name: 'date', 
          range: true,
          containerClass: 'col-sm-12 container-info col-md-2'
        },
        {
          fieldType: 'FieldInput', 
          name: 'identifier',
          type: 'text',
          label: 'N° de retiro',
          clearable: true,
          containerClass: 'col-sm-12 container-info col-md-2'
        }
      ]
      this.formFilter = {
        date: this.initialDateRange
      }
      if (this.currentTab === 1) {
        this.formFilter = {}
        this.fields = this.fields.filter(field => !['date'].includes(field.name))
        this.containerButtonsClass = 'col-sm-12 col-md-10 container-button mt-2'
      }
      this.cleanFilter()
    },
    filterForm (form) {
      form.clear = true
      this.filterList({...this.advancedFilters, ...form})
    },
    filterList (data) {
      this.filters = {}
      if (data.clear) this.filters['clear_filters'] = true
      if (data.date) {
        this.filters['pickup_date_from'] = this.$options.filters.moment(data.date.start, 'YYYY-MM-DD')
        this.filters['pickup_date_to'] = this.$options.filters.moment(data.date.end, 'YYYY-MM-DD')
      }
      if (data.identifier) this.filters['carrier_pickup_number'] = Number(data.identifier)
      if (data.country_code) this.filters['country_code'] = data.country_code.code
      if (data.organization) this.filters['marketplace'] = data.organization.id
      if (data.shipper) this.filters['company'] = data.shipper.id
      if (data.carrier) this.filters['carrier'] = data.carrier.code
      if (data.status) {
        if (this.currentTab === 0) this.filters['status'] = data.status.code
        // if (this.currentTab === 1) this.filters['status_code'] = data.status.code
        if (this.currentTab === 1) this.filters['status_in'] = data.status.map(type => type.code).toString()
      }
      if (data.evaluation_status?.length > 0) this.filters['need_evaluation'] = true
      if (data.normal_pickup_type?.length > 0) this.filters['type'] = data.normal_pickup_type.map(type => type.code).toString()
      else this.filters['type'] = 'normal,normal_frequent'
      this.$emit('updateFilters', this.filters)
    },
    cleanFilter () {
      this.formFilter = {
        date: this.initialDateRange,
        clear: true
      }
      this.clearModalFilters++
      this.filterList(this.formFilter)
    },
    openAdvancedFiltersModal () {
      this.$bvModal.show('modalAdvancedPickupFilters')
    },
    updateAdvancedFilters (data) {
      this.advancedFilters = data
      this.$bvModal.hide('modalAdvancedPickupFilters')
      this.filterList({...this.advancedFilters, ...this.formFilter})
    }
  }
}
</script>

<style>
.search-advanced {
  text-decoration: underline !important;
}
.contact-info-buttons {
  display: flex; 
  flex-direction: column;
  justify-content: flex-end;
}
.contact-info-buttons a {
  text-align: right;
  margin-bottom: 10px;
}
</style>