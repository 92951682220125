<template>
  <div class="company-dialog">
    <b-modal id="modalAdvancedPickupFilters" centered modal-class="dialog-1000 custom-dialog"
      @ok="ok" ok-variant="warning" :ok-title="$t('Buscar')" ok-only
      :title="$t('Búsqueda avanzada')">
      <div class="mt-0">
        <form-render :fields="fields" :form.sync="formFilters" @send="onAccept"
          ref="formRenderAdvancedPickupFilters"
          containerButtonsClass="col-sm-12 col-lg-4 container-button">
        </form-render>
      </div>
    </b-modal>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import TabPickupsService from '../tabPickups.service'
export default {
  props: ['currentTab', 'form', 'clearFilters'],
  data () {
    return {
      fields: [],
      formFilters: {},
      normalPickupTypes: [
        {
          text: 'Retiro diario',
          code: 'normal',
          id: 1
        },
        {
          text: 'Retiro diario asociado a frecuente',
          code: 'normal_frequent',
          id: 2
        }
      ],
      ROLES : {
        marketplace: 'marketplace',
        ecommerce: 'ecommerce',
        seller: 'seller',
        admin: 'admin',
        superadmin: 'superadmin'
      },
      normalPickupStatuses: [
        { text: 'Agendado', code: 'scheduled', id: 1 },
        { text: 'Efectuado', code: 'done', id: 2 },
        { text: 'No efectuado', code: 'failed', id: 3 },
        { text: 'Cancelado', code: 'canceled', id: 4 }
      ],
      frequentPickupStatuses: [
        { text: 'Retiro solicitado por MarketPlace', code: 'requested_marketplace', id: 1 },
        { text: 'Retiro aceptado', code: 'accepted', id: 2 },
        { text: 'Retiro desactivado', code: 'disabled', id: 3 },
        { text: 'Retiro rechazado', code: 'rejected', id: 4 },
        { text: 'Retiros pendientes', code: 'update_pending', id: 5 }
      ],
      fieldSkipLine: { name: 'line', useSkeleton: true, skipLine: true, containerClass: 'col-12' },
      tabPickupsService: new TabPickupsService(this)
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession'
    })
  },
  watch: {
    mySession () {
      if (this.mySession.id) this.setInitialData()
    },
    form: {
      handler(value) {
        this.formFilters = {
          ...this.formFilters,
          ...value
        }
      },
      deep: true
    },
    clearFilters () {
      // Limpiar filtros avanzados
      this.formFilters = {
        normal_pickup_type: this.normalPickupTypes
      }
    }
  },
  mounted () {
    this.getAllInitialData()
  },
  methods: {
    getAllInitialData () {
      if (this.mySession.id) this.setInitialData()
    },
    setInitialData () {
      this.fields = [
        // Filtros de place
        {
          fieldType: 'FieldSelect', 
          clearable: true, 
          label: 'País', 
          name: 'country_code',
          containerClass: 'col-sm-12 container-info', 
          addFlags: true
        },
        {
          fieldType: 'FieldSelect', 
          name: 'organization', 
          label: 'Marketplace', 
          containerClass: 'col-sm-12 container-info', 
          searchOnType: { 
            fx: this.searchOrganizations, 
            nChars: 3, 
            debounce: 300, 
            allowSearchAsValue: true, 
            persistSearch: true 
          } 
        },
        {
          fieldType: 'FieldSelect', 
          name: 'shipper', 
          label: 'Empresa', 
          containerClass: 'col-sm-12 container-info', 
          searchOnType: { 
            fx: this.searchShippers, 
            nChars: 3, 
            debounce: 300, 
            allowSearchAsValue: true, 
            persistSearch: true 
          } 
        },
        {
          fieldType: 'FieldSelect', 
          name: 'carrier', 
          label: 'Courier', 
          containerClass: 'col-sm-12 container-info'
          // searchOnType: { 
          //   fx: this.getCarriers, 
          //   nChars: 3, 
          //   debounce: 300, 
          //   allowSearchAsValue: true, 
          //   persistSearch: true 
          // }
        },
        {
          fieldType: 'FieldSelect', 
          name: 'status', 
          label: 'Estado', 
          containerClass: 'col-sm-12 container-info'
        },
        {
          fieldType: 'FieldSelect',
          name: 'evaluation_status',
          label: 'Estado de evaluación',
          containerClass: 'col-sm-12 container-info',
          multiple: true,
          options: [
            {
              text: 'Retiros pendientes de evaluación',
              id: 'need_evaluation'
            }
          ]
        },
        {
          fieldType: 'FieldSelect', 
          name: 'normal_pickup_type', 
          label: 'Tipo de retiro', 
          containerClass: 'col-sm-12 container-info',
          multiple: true,
          options: this.normalPickupTypes
        }
      ]
  
      // Inicializamos la vista con los dos tipos de retiros normales
      this.formFilters = {
        normal_pickup_type: this.normalPickupTypes
      }
      this.fields[this.selectionIndex('status')].options = this.normalPickupStatuses

      if (this.currentTab === 1) {
        const statusField = this.fields[this.selectionIndex('status')]
        statusField.options = this.frequentPickupStatuses
        statusField.multiple = true
        this.formFilter = {}
        this.fields = this.fields.filter(field => !['normal_pickup_type', 'evaluation_status'].includes(field.name))
        this.containerButtonsClass = 'col-sm-12 col-md-10 container-button mt-2'
      }

      if (this.mySession.role !== this.ROLES.admin) {
        this.editFormByRole()
      } else {
        this.searchCountries()
      }
      this.getCarriers()
    },
    /** 
     * Función que saca campos del formulario segun roles de usuario
    */
    editFormByRole () {
      if (this.mySession.role === this.ROLES.ecommerce || this.mySession.role === this.ROLES.seller) this.fields = this.fields.filter(field => !['organization', 'shipper', 'country_code'].includes(field.name))
      if (this.mySession.role === this.ROLES.marketplace) this.fields = this.fields.filter(field => !['organization', 'country_code'].includes(field.name))
    },
    /** 
    * Busca el indice de un campo segun su nombre
    */
    selectionIndex(name) {
      return this.fields.findIndex(el => el.name === name)
    },
    searchOrganizations (value) {
      return this.tabPickupsService.callService('getOrganizations', { name: value }, null)
        .then(response => {
          return response.data.map(el => ({ ...el, text: `${el.id} - ${el.name}` }))
        })
        .catch(error => console.error(error))
    },
    searchShippers(value) {
      let service = 'getShippers'
      const params = {}
      if (this.mySession.role === 'marketplace') {
        service = 'getShippersByOrganizationPucon'
        params.organization_id = this.mySession.organization.id
      }
      return this.tabPickupsService.callService(service, {search: value}, params)
        .then(response => {
          return response.data.map(el => ({...el, text: `${el.id} - ${el.name1 ? el.name1 : el.name}`}))
        })
        .catch(error => console.error(error))
    },
    getCarriers(value) {
      const queryParams = {
        // materials_enabled: true,
        // name: value
        limit: 200
      }
      this.tabPickupsService.callService('getCarriers', queryParams) 
        .then(response => {
          const options = response.data.map(el => ({ ...el, text: `${el.code} - ${el.name}`, id: el.code }))
          this.fields[this.selectionIndex('carrier')].options = options
        })
        .catch(error => console.error(error))
    },
    searchCountries () {
      return this.tabPickupsService.callService('getCountries', null, null)
        .then(response => {
          const options = response.data.map(el => ({...el, text: `${el.name1 ? el.name1 : el.name}`}))
          // Sacamos del array Argentina y Ecuador
          // options = options.filter(country => !['AR', 'EC'].includes(country.code))
          this.fields[this.selectionIndex('country_code')].options = options

        })
        .catch(error => console.error(error))
    },
    ok (e) {
      e.preventDefault()
      this.$refs.formRenderAdvancedPickupFilters.checkForm()
    },
    onAccept () {
      this.$emit('updateAdvancedFilters', this.formFilters)
    }
  }
}
</script>

<style>

</style>