<template>
  <div>
    <b-modal id="modalCancelPickup" 
      no-close-on-esc
      no-close-on-backdrop
      centered
      modal-class="modal-warning dialog-800 custom-dialog"
      hide-footer>
      <div>
        <!-- Formulario -->
        <h3 class="mb-2 text-center text-primary">Cancelar retiro</h3>
        <form-render ref="formRenderCancelNormalPickup"
        :fields="fields" :form.sync="form">
        </form-render>
        <b-button @click="onSubmit" :disabled="disabled.cancelButton"  variant="warning" class="mt-2 push-right">
          {{$t('Confirmar')}}
          <feather-icon v-if="!loading.cancelButton" icon="SendIcon"/> 
          <i v-else :class="['fa', 'fa-spinner', 'fa-spin']"/> 
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TabPickupsService from '../tabPickups.service'
export default {
  props: ['pickup'],
  data () {
    return {
      form: {},
      fields: [],
      disabled: {
        cancelButton: true
      },
      loading: {
        cancelButton: false
      },
      reasons: [
        { text: 'No es necesario el retiro', id: 'not_necessary' },
        { text: 'El retiro posee datos incorrectos', id: 'incorrect_data' }
      ],
      tabPickupsService: new TabPickupsService(this)
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession'
    })
  },
  watch: {
    mySession () {
      if (this.mySession.id) this.setInitialData()
    },
    'form.reason' () {
      if (this.form.reason) this.disabled.cancelButton = false
      else this.disabled.cancelButton = true
    },
    'pickup': {
      handler() {
        this.form = {}
      },
      deep: true
    }
  },
  mounted () {
    this.getAllInitialData()
  },
  methods: {
    getAllInitialData () {
      if (this.mySession.id) this.setInitialData()
    },
    setInitialData () {
      this.fields = [
        {
          fieldType: 'FieldSelect', 
          name: 'reason', 
          label: 'Motivo',
          validation: 'required',
          containerClass: 'col-sm-12 container-info', 
          options: this.reasons 
        }
      ]
    },
    onSubmit() {
      this.loading.cancelButton = true
      this.disabled.cancelButton = true
      const { role, first_name, last_name, email } = this.mySession
      const queryParams = {
        reason_code: this.form.reason.id,
        user: {
          role,
          first_name,
          last_name,
          email
        }
      }
      const { identifier, company } = this.pickup
      const ulrParams = {
        identifier,
        shipper_id: company.id
      }
      this.tabPickupsService.callService('cancelPickup', queryParams, ulrParams, { fullResponseError: true })
        .then(resp => {
          this.$success(this.$t('msg-success-cancel-pickup'))
          const pickup_response = {
            ...resp.data,
            identifier: this.pickup.identifier
          }
          this.$emit('updatePickupStatus', pickup_response)
        })
        .catch(error => {
          this.$alert(error.data)
        })
        .finally(() => {
          this.$bvModal.hide('modalCancelPickup')
          this.loading.cancelButton = false
          this.disabled.cancelButton = false
        })
    }
  }
}
</script>

<style>

</style>