<template>
  <div>
    <b-modal id="modalFrequentPickupDetails" size="lg" ok-variant="outline-success"
      ok-only :ok-title="$t('Cerrar')" centered>
        <template #modal-title>
          <div class="d-flex justify-content-between align-items-center">   
            <b-button v-if="detailPickup.canReturnToList && (conditionsByPermissions.is_team_pickup || conditionsByPermissions.frequent_marketplace_supervisor)" @click="backToPendingRequest" variant="warning">
              <feather-icon icon="ArrowLeftIcon"/> Volver al listado
            </b-button>
            <h5 class="flex-grow-1 text-center mb-0">Información del retiro</h5>
          </div>
        </template>

        <div v-if="detailPickup && detailPickup.identifier">
          <div class="row">
            <!-- Identifier -->
            <div class="col-sm-12 col-md-6 mb-1 container-info">
              <label><b>{{$t('Número de retiro')}}</b></label>
              <span class="ml-1">{{ detailPickup.identifier || '--' }}</span>
            </div>
            <!-- Estado retiro -->
            <div class="col-sm-12 col-md-6 mb-1 container-info">
              <label><b>{{$t('Estado de retiro')}}</b></label>
              <span class="ml-1">{{ detailPickup.status.name || '--' }}</span>
            </div>
          </div>

          <div class="row">
            <!-- Empresa -->
            <div class="col-sm-12 col-md-6 mb-1 container-info">
              <label><b>{{$t('Empresa')}}</b></label>
              <span class="ml-1">{{ detailPickup.company.name || '--' }}</span>
            </div>
            <!-- Courier -->
            <div class="col-sm-12 col-md-6 mb-1 container-info">
              <label><b>{{$t('Courier')}}</b></label>
              <span class="ml-1">{{ detailPickup.carrier.name || '--' }}</span>
            </div>
          </div>

          <div class="row">
            <!-- Bodega -->
            <div class="col-sm-12 col-md-4 mb-1 container-info">
              <label><b>{{$t('Lugar de retiro / Bodega')}}</b></label>
              <span class="ml-1">{{ detailPickup.warehouse.name || '--' }}</span>
            </div>
            <!-- Place_level3 -->
            <div class="col-sm-12 col-md-4 mb-1 container-info">
              <label><b>{{$t('Place_level3')}}</b></label>
              <span class="ml-1">{{ detailPickup.address.place || '--' }}</span>
            </div>
            <!-- Dirección -->
            <div class="col-sm-12 col-md-4 mb-1 container-info">
              <label><b>{{$t('Dirección')}}</b></label>
              <span v-if="detailPickup.address.full_address">
                {{ displayedAddress }}
                <a class="link-show-address text-primary" v-if="showFullAddress" @click="toggleFullAddress">({{ showFullAddress }})</a>
              </span>
            </div>
          </div>

          <div class="row">
            <!-- Bultos -->
            <div class="col-sm-12 col-md-4 mb-1 container-info">
              <label><b>{{$t('Cantidad de bultos')}}</b></label>
              <span class="ml-1">{{ detailPickup.qty || '--' }}</span>
            </div>
            <!-- KG -->
            <div class="col-sm-12 col-md-4 mb-1 container-info">
              <label><b>{{$t('Peso total en KG')}}</b></label>
              <span class="ml-1">{{ detailPickup.weight || '--' }}</span>
            </div>
            <!-- Dimensiones -->
            <div class="col-sm-12 col-md-4 mb-1 container-info">
              <label><b>{{$t('Dimensiones')}}</b></label>
              <span class="ml-1">{{ detailPickup.dimensions || '--' }}</span>
            </div>
          </div>

          <!-- Dimensiones -->
          <div class="row">
            <div class="col-md-12">
              <label><b>{{$t('Volumen promedio')}}</b></label>
              <div> 
                <span class="text-danger">*</span> Volumen unitario promedio de los envíos o el volumen del envío más grande.
              </div>
            </div>
            <div class="col-md-12 row-size">
              <div class="flex" style="display: flex; flex-direction: column; align-items: center;">
                <img :src="packageSizeImgs.letter" alt="Size_XS" width="25">                                                                             
                <label class="radio-inline">
                  <b-form-radio v-model="detailPickup.size" :disabled="true"  name="some-radios" value="xs">Sobre</b-form-radio>
                </label>
              </div>
          
              <div class="flex" style="display: flex; flex-direction: column; align-items: center;">
                <img :src="packageSizeImgs.small" alt="Size_S" width="36">                                                                                                                                                                          
                <label class="radio-inline">
                  <b-form-radio v-model="detailPickup.size" :disabled="true"  name="some-radios" value="s">Pequeño</b-form-radio>
                </label>
              </div>
          
              <div class="flex" style="display: flex; flex-direction: column; align-items: center;">
                <img :src="packageSizeImgs.medium" alt="Size_M" width="53">                                                                                      
                <label class="radio-inline">
                  <b-form-radio v-model="detailPickup.size" :disabled="true"  name="some-radios" value="m">Mediano</b-form-radio>
                </label>
              </div>
          
              <div class="flex" style="display: flex; flex-direction: column; align-items: center;">
                <img :src="packageSizeImgs.large" alt="Size_L" width="68">                                                                                      
                <label class="radio-inline">
                  <b-form-radio v-model="detailPickup.size" :disabled="true"  name="some-radios" value="l">Grande</b-form-radio>
                </label>
              </div>
          
              <div class="flex" style="display: flex; flex-direction: column; align-items: center;">
                <img :src="packageSizeImgs.extraLarge" alt="Size_C" width="81" style="opacity: .7;"> 
                <label class="radio-inline">
                  <b-form-radio v-model="detailPickup.size" :disabled="true"  name="some-radios" value="c">Más Grande</b-form-radio>
                </label>
              </div>
            </div>

            <div class="col-md-12 mt-1" v-if="detailPickup.size === 'c'">
              <label><b>{{$t('Descripción de producto')}}</b></label>
              <div class="ml-1">{{ detailPickup.description || '--' }}</div>
            </div>
          </div>
          <hr>

          <div class="row">
            <!-- Pallets -->
            <div class="col-sm-12 col-md-6 mb-1 container-info">
              <label><b>{{$t('Cantidad de pallets')}}</b></label>
              <span class="ml-1">{{ detailPickup.qty_pallets || '--' }}</span>
            </div>
            <!-- Identificador del courier -->
            <div class="col-sm-12 col-md-6 mb-1 container-info">
              <label><b>{{$t('Identificador del courier')}}</b></label>
              <span class="ml-1">{{ detailPickup.carrier_pickup_number || '--' }}</span>
            </div>
          </div>

          <div class="row">
            <!-- Días de retiro -->
            <div class="col-sm-12 col-md-6 mb-1 container-info">
              <label><b>{{$t('Días de retiro')}}</b></label>
              <div class="ml-1">
                <b-badge
                  v-for="day in selectedDays"
                  :key="day.value"
                  variant="primary"
                  class="mr-1">
                    {{ day.name }}
                </b-badge>
              </div>
            </div>
            <!-- Rango horario -->
            <div class="col-sm-12 col-md-6 mb-1 container-info">
              <label><b>{{$t('Rango horario')}}</b></label>
              <span class="ml-1">{{ detailPickup.range_time || '--' }}</span>
            </div>

            <!-- Rango horario dia sabado -->
            <div v-if="detailPickup.range_time_exceptions" class="col-sm-12 col-md-6 mb-1 container-info">
              <label><b>{{$t('Rango horario día sabado')}}</b></label>
              <span class="ml-1">{{ detailPickup.range_time_exceptions.sat || '--' }}</span>
            </div>
          </div>

          <div class="row">
            <!-- Fecha inicio -->
            <div class="col-sm-12 col-md-6 mb-1 container-info">
              <label><b>{{$t('Fecha de inicio')}}</b></label>
              <span class="ml-1">{{ detailPickup.last_request.effective_date || '--' }}</span>
            </div>
          </div>

          <div class="row">
            <!-- Nombre contacto -->
            <div class="col-sm-12 col-md-6 mb-1 container-info">
              <label><b>{{$t('Nombre contacto')}}</b></label>
              <span class="ml-1">{{ detailPickup.contact.name || '--' }}</span>
            </div>
            <!-- Teléfono de contacto -->
            <div class="col-sm-12 col-md-6 mb-1 container-info">
              <label><b>{{$t('Teléfono de contacto')}}</b></label>
              <span class="ml-1">{{ detailPickup.contact.phone || '--' }}</span>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12 col-md-12 mb-1 container-info">
              <label><b>{{$t('Observación')}}</b></label>
              <span class="ml-1">{{ detailPickup.information || '--' }}</span>
            </div>
          </div>
        </div>
    </b-modal>
  </div>
</template>

<script>
import TabPickupsService from '../tabPickups.service'
export default {
  props: ['pickup'],
  data () {
    return {
      packageSizeImgs: {
        letter: require('@/assets/images/images-on-components/packages-size/package-size-letter.png'),
        small: require('@/assets/images/images-on-components/packages-size/package-size-small.png'),
        medium: require('@/assets/images/images-on-components/packages-size/package-size-medium.png'),
        large: require('@/assets/images/images-on-components/packages-size/package-size-large.png'),
        extraLarge: require('@/assets/images/images-on-components/packages-size/package-size-extra-large.png')
      },
      frequentPickupsDays: [
        { label:'Lu', name: 'Lunes', value:'mon' },
        { label:'Ma', name: 'Martes', value:'tue' },
        { label:'Mi', name: 'Miércoles', value:'wed' },
        { label:'Ju', name: 'Jueves', value:'thu' },
        { label:'Vi', name: 'Viernes', value:'fri' },
        { label:'Sa', name: 'Sabado', value:'sat' }
      ],
      selectedDays: [],
      nChar: 15,
      showFullAddress: null,
      displayedAddress: null,
      detailPickup: {
        pickup_days: {}
      },
      conditionsByPermissions: {},
      tabPickupsService: new TabPickupsService(this)
    }
  },
  watch: {
    // 'pickup': {
    //   handler(value) {
    //     this.detailPickup = value
    //     this.showFullAddress = value.address.full_address.length > this.nChar ? 'Ver más' : null
    //     this.updateDisplayedAddress()
    //   },
    //   deep: true
    // },
    // 'detailPickup.pickup_days': {
    //   handler(value) {
    //     this.selectedDays = value.map(day => this.frequentPickupsDays.find(frequentDay => frequentDay.value === day))
    //   },
    //   deep: true
    // }
  },
  mounted() {
    // Objeto con condiciones según el rol del usuario
    this.conditionsByPermissions = this.tabPickupsService.conditionsByPermissions(this.$session.get('cas_user'))
  },
  methods: {
    toggleFullAddress() {
      if (this.showFullAddress === 'Ver más') {
        this.showFullAddress = 'Ocultar'
      } else {
        this.showFullAddress = 'Ver más'
      }
      this.updateDisplayedAddress()
    },
    updateDisplayedAddress() {
      const { full_address, place_name, place, information } = this.detailPickup.address
      const infoPart = information !== null ? ` - ${information}` : ''
      this.displayedAddress = this.showFullAddress === 'Ocultar' ? `${full_address} - ${place_name || place}${infoPart}` : this.truncateString(full_address)
    },
    truncateString(str) {
      return str.length <= this.nChar ? str : `${str.slice(0, this.nChar)}...`
    },
    showPickupDetails (value) {
      this.detailPickup = value
      this.showFullAddress = value.address.full_address.length > this.nChar ? 'Ver más' : null
      this.updateDisplayedAddress()
      this.selectedDays = value.pickup_days.map(day => this.frequentPickupsDays.find(frequentDay => frequentDay.value === day))
      this.$bvModal.show('modalFrequentPickupDetails')
    },
    backToPendingRequest () {
      this.$bvModal.show('modalPendingRequest')
      this.$bvModal.hide('modalFrequentPickupDetails')
    }
  }
}
</script>

<style>

</style>