<template>
  <div>
    <b-modal id="modalCreateNormalPickup"
    :title="$t('Crear retiro diario')"
    no-close-on-esc
    no-close-on-backdrop
    centered
    size="lg"
    @show="close"
    hide-footer>
      <div class="mt-0">
        <form-render ref="formRenderCreateNormalPickup" 
          :fields="fields"
          :key="keyFormRender"
          :form.sync="pickupRequest"
          @send="createPickup">
          <!-- Deliveries Field -->
          <template #deliveries v-if="display.deliveriesField">
            <div>
              <label class="label-form" title="Frecuencia de envío del reporte">
                <span>Seleccione los manifiestos que serán retirados</span>
                <span class="text-danger"> *</span>
                <span v-if="loading.getSummaries">
                  <b-spinner class="ml-1" small label="Spinning"></b-spinner> Buscando manifiestos...
                </span>
              </label>
              <v-select
                label="text"
                append-to-body
                v-model="pickupRequest.summary"
                placeholder="seleccione manifiesto"
                :clearable="false"
                :disabled="!pickupRequest.warehouse"
                :options="deliveriesOptions"
                @input="onChangeSummary">
                  <template slot="no-options">
                    <span>
                      {{$t('No hay opciones cargadas.')}}
                    </span>
                  </template>
              </v-select>
              <!-- mensaje de busqueda manifiestos -->
              <span v-if="summariesMsg" class="text-danger"> {{ summariesMsg }} </span>
            </div>
            <div class="mt-1">
              <div class="col-md-8 mb-1" v-for="(summary, index) in selectedSummariesList" :key="index">
                <b-badge class="text-center col-md-4" style="font-size:14px; padding: 7px;" variant="secondary">
                  {{ summary.id }} <i @click="removeSummaryInList(summary)" class="fa fa-times ml-3 fa-4x" style="cursor: pointer;"></i>
                </b-badge>
                <span class="col-md-4" style="font-size:14px;">
                  <b-button @click="showSummaryDetails(summary)" variant="link">Ver detalle de envíos</b-button>
                </span>
              </div>
            </div>
          </template>
        </form-render>
      </div>
      <template >
        <b-button @click="ok" class="push-right" variant="warning" :disabled="loading.createPickup">
          {{$t('Crear retiro')}}
          <feather-icon v-if="!loading.createPickup" icon="DownloadIcon"/>
          <i v-else :class="['fa', 'fa-spinner', 'fa-spin']"/> 
        </b-button>
      </template>
    </b-modal>

    <!-- Modal detalle manifiesto -->
    <modal-summary-details ref="summaryModal" :summary="selectedSummary" @updateSummary="updateSummary"></modal-summary-details>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import * as moment from 'moment'
import vSelect from 'vue-select'
import ModalSummaryDetails from './ModalSummaryDetails.vue'
import TabPickupsService from '../tabPickups.service'
export default {
  components: {
    vSelect,
    ModalSummaryDetails
  },
  data () {
    return {
      fields: [],
      pickupRequest: {},
      keyFormRender: 0,
      carrierConfig: {},
      loading: {
        createPickup: false,
        getSummaries: false
      },
      selectedSummary: {},
      selectedSummariesList: [],
      deliveriesOptions: [],
      allSelectedOts: [],
      summariesMsg: null,
      display: {
        deliveriesField: false
      },
      ROLES : {
        marketplace: 'marketplace',
        ecommerce: 'ecommerce',
        seller: 'seller',
        admin: 'admin',
        superadmin: 'superadmin'
      },
      sizePickup: [
        { id: 'xs', text: 'Sobre' },
        { id: 's', text: 'Pequeño' },
        { id: 'm', text: 'Mediano' },
        { id: 'l', text: 'Grande' },
        { id: 'c', text: 'Más Grande' }
      ],
      currentDate: new Date(),
      maxDate: new Date(),
      daysInCalendar: 60,
      countriesData: {
        CL: {
          phone_code:56,
          phone_digits:9
        },
        MX: {
          phone_code:52,
          phone_digits:10
        },
        CO: {
          phone_code:57,
          phone_digits:13
        },
        PE: {
          phone_code:51,
          phone_digits:9
        }
      },
      fieldSkipLine: { name: '', useSkeleton: true, skipLine: true, containerClass: 'col-12' },
      dimensionLabel: { name: 'dimensionLine', label: 'Medidas (Alto, Ancho, Largo en cm)', useSkeleton: true, skipLine: true, containerClass: 'col-12' },
      tabPickupsService: new TabPickupsService(this)
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession'
    })
  },
  watch: {
    mySession () {
      if (this.mySession.id) this.setInitialData()
    },
    'pickupRequest.carrier': {
      handler(carrier) {
        if (!carrier) {
          this.fields = this.fields.filter(field => !['service_type', 'line'].includes(field.name))
        }
      },
      deep: true
    }
  },
  mounted () {
    this.getAllInitialData()
  },
  methods: {
    getAllInitialData () {
      if (this.mySession.id) this.setInitialData()
    },
    setInitialData () {
      this.fields = [
        {
          fieldType: 'FieldSelect',
          name: 'shipper',
          label: 'Empresa',
          validation: 'required',
          containerClass: 'col-md-6 container-info',
          change: this.onChangeCompany,
          clearable: false,
          searchOnType: {
            fx: this.getShippers,
            nChars: 2,
            debounce: 300,
            allowSearchAsValue: true,
            persistSearch: true
          } 
        },
        {
          fieldType: 'FieldSelect',
          name: 'carrier',
          label: 'Courier',
          validation: 'required',
          containerClass: 'col-md-6 container-info',
          change: this.onChangeCarrier,
          clearable: false
        },
        {
          fieldType: 'FieldSelect',
          name: 'warehouse',
          dependency: 'carrier',
          label: 'Lugar de retiro/Bodega',
          validation: 'required',
          containerClass: 'col-md-6 container-info',
          change: this.onChangeWarehouse,
          clearable: false
        },
        {
          fieldType: 'FieldInput',
          name: 'qty',
          label: 'Cantidad de bultos',
          validation: 'required',
          containerClass: 'col-md-3 container-info',
          change: this.checkConditions
        },
        {
          fieldType: 'FieldInput',
          name: 'weight',
          // dependency: 'qty',
          type: 'number',
          label: 'Peso total en kg',
          validation: 'required',
          containerClass: 'col-md-3 container-info',
          change: this.checkConditions
        },
        {
          fieldType: 'FieldRadio',
          name: 'size',
          label: 'Volumen promedio',
          validation: 'required',
          containerClass: 'col-md-12 my-1 border-bottom pb-2',
          change: this.onChangeSize,
          align: 'h',
          optionalSecondLabel: 'Ingresar el volumen unitario promedio de los envíos o el volumen del envío más grande.',
          options: this.sizePickup
        },
        { name: 'deliveries', useSlot: true, containerClass: 'col-md-12 container-info' },
        {
          fieldType: 'FieldDatepicker',
          label: 'Fecha de retiro',
          name: 'pickup_date',
          dependency: 'weight',
          propsVCalendar: { 
            'min-date': this.currentDate,
            'max-date': this.maxDate.setDate(this.currentDate.getDate() + this.daysInCalendar)
          },
          disabledDates: { weekdays: [1, 7] },
          validation: 'requiredDate',
          containerClass: 'col-md-6 container-info',
          change: this.onChangePickupDate
        },
        {
          fieldType: 'FieldSelect',
          name: 'range_time',
          dependency: 'pickup_date',
          label: 'Rango horario',
          validation: 'required',
          containerClass: 'col-md-6 container-info',
          keyFormRender: 0
        },
        {
          fieldType: 'FieldInput',
          name: 'contact_name',
          label: 'Nombre contacto bodega',
          validation: 'required',
          containerClass: 'col-md-6 container-info'
        },
        {
          fieldType: 'FieldInput',
          name: 'contact_phone',
          dependency: 'shipper',
          type: 'number',
          label: 'Teléfono de contacto',
          containerClass: 'col-md-6 container-info',
          validation: 'required',
          prepend: { text: '+', color: '#BBBBBB' }
        },
        {
          fieldType: 'FieldInput',
          name: 'contact_email',
          type: 'email',
          label: 'Correo contacto bodega',
          validation: 'required|email',
          containerClass: 'col-md-12 container-info'
        },
        { 
          fieldType: 'FieldTextarea',
          name: 'information',
          label: 'Observación',
          containerClass: 'col-md-12 container-info'
        }
      ]
      if (this.mySession.role === this.ROLES.ecommerce || this.mySession.role === this.ROLES.seller) {
        // Se saca el campo de shipper del formulario de creacion y se setea el campo con el id del usuario
        this.fields = this.fields.filter(field => !['shipper'].includes(field.name))
        this.pickupRequest.shipper = this.mySession.shipper
        this.onChangeCompany()
      }
      this.pickupRequest.size = { id: 'xs', text: 'Sobre' }
      this.pickupRequest.pickup_date = moment()
    },
    // FUNCIONES GET
    getShippers(value) {
      let service = 'getShippers'
      const params = {}
      const payload = {
        search: value,
        extra_fields: 'country'
      }
      if (this.mySession.role === 'marketplace') {
        service = 'getShippersByOrganizationPucon'
        params.organization_id = this.mySession.organization.id
        // payload.extra_fields = 'country'
        // payload.search = value
        // delete payload.name
      }
      return this.tabPickupsService.callService(service, payload, params)
        .then(response => {
          return response.data.map(el => ({...el, text: `${el.id} - ${el.name1 ? el.name1 : el.name}`}))
        })
        .catch(error => console.error(error))
    },
    getCarriers(shipper_id) {
      const queryParams = {
        extra_fields: 'customization,normal_configuration'
      }
      return this.tabPickupsService.callService('getCarriersDistribution', queryParams, { shipper_id })
        .then(resp => {
          // Se rellena el selector "Carrier" con el listado de carriers
          const carriers = resp.data.map(el => ({ ...el, text: el.name }))
          // this.$refs.formRenderCreateNormalPickup.setSelectOptions('carrier', carriers)
          this.setSelectOptions('carrier', carriers)
        })
        .catch(error => console.error(error))
    },
    getWarehouses(shipper_id) {
      this.tabPickupsService.callService('getWarehousesApiPlatform', null, { shipper_id })
        .then(resp => {
          const warehouses = resp.data.map(warehouse => ({ ...warehouse, text: `${warehouse.name} - ${warehouse.place_name}` }))
          // this.$refs.formRenderCreateNormalPickup.setSelectOptions('warehouse', warehouses)
          this.setSelectOptions('warehouse', warehouses)
        })
        .catch(error => console.error(error))
    },
    getNonWorkingDays (carrier_code) {
      const queryParams = {
        company_id: this.pickupRequest.shipper.id,
        days: this.daysInCalendar,
        place_id: this.pickupRequest.warehouse.place_id
      }
      return this.tabPickupsService.callService('getNonWorkingDays', queryParams, { carrier_code })
        .then(resp => {
          // Actualizamos los dias invalidos del calendario "pickup_date"
          this.fields[this.selectionIndex('pickup_date')].disabledDates = resp.data.map(date => moment(date).toDate())
        })
        .catch(error => console.error(error))
    },
    getSummaries() {
      this.loading.getSummaries = true
      this.selectedSummariesList = []
      this.summariesMsg = null
      this.deliveriesOptions = []
      const { shipper, warehouse, carrier } = this.pickupRequest
      const group_code = `${shipper.id}_${warehouse.id}_${carrier.code}`
      const queryParams = {
        'status_code[0]': 'CLOSED',
        'status_code[1]': 'OPEN',
        'limit': 20,
        group_code,
        'updated_at_to': moment.utc().format('YYYY-MM-DD HH:mm:ss'),
        'with[]': 'deliveries'
      }
      this.tabPickupsService.callService('getSummaries', queryParams)
        .then(resp => {
          this.deliveriesOptions = resp.data.map(summary => ({ ...summary, text: `ID ${summary.id} - ${summary.deliveries.length} bultos - ${summary.carrier_code}` }))
          if (this.deliveriesOptions.length === 0) this.summariesMsg = '** No se han encontrado manifiestos **'
        })
        .catch(error => {
          console.error(error)
          this.summariesMsg = '** No se han encontrado manifiestos **'
        })
        .finally(() => this.loading.getSummaries = false)
    },
    // FUNCIONS SET
    setCarrierServices() {
      const existingServiceField = this.fields.find(field => field.name === 'service_type')
      
      if (this.pickupRequest.carrier.services_configuration?.length > 0) {
        const servicesOptions = this.pickupRequest.carrier.services_configuration.map(service => ({ ...service, text: service.name, id: service.code }))
        const serviceField = {
          fieldType: 'FieldSelect',
          name: 'service_type',
          label: 'Servicio courier',
          options: servicesOptions,
          validation: 'required',
          containerClass: 'col-md-6 container-info'
        }
        // Verifica si el campo ya existe
        if (!existingServiceField) {
          // Agrega el campo solo si no existe
          this.fields.splice(this.selectionIndex('carrier') + 1, 0, serviceField, this.fieldSkipLine)
        } else {
          // De lo contrario solo se cargan las options
          this.fields[this.selectionIndex('service_type')].options = servicesOptions
        }
      } else {
        // Se elimina el campo si no tiene servicio
        this.fields = this.fields.filter(field => !['service_type', 'line'].includes(field.name))
      }
      delete this.pickupRequest.service_type
    },
    setRangeTimes() {
      // Se rellena el selector "Rango horario" con los rangos horarios del carrier seleccionado
      // const rangeTime = this.pickupRequest.carrier.normal_configuration.range_time.map(el => ({ ...el, text: el.label }))
      this.carrierConfig = {
        ...this.pickupRequest.carrier.normal_configuration,
        time_zone: this.pickupRequest.carrier.time_zone
      }
      const rangeTime = this.pickupRequest.carrier.normal_configuration.range_time.map((el, index) => ({ 
        ...el,
        id: index,
        text: el.label,
        disabled: this.isRangeDisabled(el)
      }))
      this.$refs.formRenderCreateNormalPickup.setSelectOptions('range_time', rangeTime)
    },
    setWarehouseContacEmail(warehouse) {
      if (warehouse.contact_email) this.pickupRequest.contact_email = warehouse.contact_email
      this.$refs.formRenderCreateNormalPickup.updateUIForm()
    },
    setDimensionFields(size) {
      if (size.id === 'c') {
        // Si es "C" se despliegan los nuevos campos (alto, ancho, largo y descripción).
        const customSizeFields = [
          this.dimensionLabel,
          {
            fieldType: 'FieldInput',
            name: 'height',
            type: 'number',
            label: 'Alto',
            validation: 'required',
            containerClass: 'col-md-2 container-info my-1 border-bottom pb-2',
            change: this.checkConditions
          },
          {
            fieldType: 'FieldInput',
            name: 'width',
            type: 'number',
            label: 'Ancho',
            validation: 'required',
            containerClass: 'col-md-2 container-info my-1 border-bottom pb-2',
            change: this.checkConditions
          },
          {
            fieldType: 'FieldInput',
            name: 'length',
            type: 'number',
            label: 'Largo',
            validation: 'required',
            containerClass: 'col-md-2 container-info my-1 border-bottom pb-2',
            change: this.checkConditions
          },
          {
            fieldType: 'FieldTextarea',
            name: 'description',
            label: 'Descripción de los productos',
            validation: 'required',
            containerClass: 'col-md-6 container-info my-1 border-bottom pb-2'
          }
        ]
        this.fields.splice(this.selectionIndex('size') + 1, 0, ...customSizeFields)
      } else {
        // Si no es "C" no se muestra nada y se limpian los campos
        this.fields = this.fields.filter(field => !['height', 'width', 'length', 'description', 'dimensionLine'].includes(field.name))
        delete this.pickupRequest.height
        delete this.pickupRequest.width
        delete this.pickupRequest.length
        delete this.pickupRequest.description
      }

    },
    setDeliveriesSelect () {
      if (this.pickupRequest.carrier.normal_configuration.need_deliveries) {
        this.display.deliveriesField = true
      } else {
        this.display.deliveriesField = false
      }
      this.selectedSummariesList = []
      this.deliveriesOptions = []
    },
    isRangeDisabled(range) {
      const { time_zone, time_limit } = this.carrierConfig
      const pickupDate = moment(this.pickupRequest.pickup_date)
      const todayWithTimeZone = this.applyTimeZone(time_zone)
      const sameDay = todayWithTimeZone.isSame(pickupDate, 'day')

      // Si no es el mismo dia (hoy) retorna false al instante ya que no estamos agendando para hoy mismo
      if (!sameDay) return false
      if (!time_limit) return false

      const todayHour = todayWithTimeZone.format('HH:mm')
      if (time_limit < todayHour) return true

      const timeToStr = range.to
      const timeToMinusTwoHours = moment(timeToStr, 'HH:mm').subtract(2, 'hours').format('HH:mm')
      if (timeToMinusTwoHours < todayHour) return true

      return false
    },
    applyTimeZone(timeZone) {
      const date = new Date()
      return moment(date.toLocaleString('en-US', { timeZone }))
    },
    // FUNCIONES ONCHANGE
    onChangeCompany () {
      // Limpiamos los campos correspondientes al cambio de empresa
      delete this.pickupRequest.carrier
      delete this.pickupRequest.warehouse
      delete this.pickupRequest.range_time
      // delete this.pickupRequest.pickup_date
      // Se limpia el contact_phone para prevenir errores de codigo de pais
      delete this.pickupRequest.contact_phone
      this.pickupRequest.qty = null

      if (!this.pickupRequest.shipper) {
        this.$refs.formRenderCreateNormalPickup.setSelectOptions('carrier', [])
        this.$refs.formRenderCreateNormalPickup.setSelectOptions('warehouse', [])
        this.fields[this.selectionIndex('contact_phone')].prepend = { text: '+', color: '#BBBBBB' }
        delete this.fields[this.selectionIndex('contact_phone')].max
      } else {

        const countryCode = this.pickupRequest.shipper.country.code
        if (this.countriesData[countryCode]) {
          const phoneCode = this.countriesData[countryCode].phone_code
          const phoneDigits = this.countriesData[countryCode].phone_digits
          this.fields[this.selectionIndex('contact_phone')].prepend = { text: `+${phoneCode}` }
          this.fields[this.selectionIndex('contact_phone')].max = phoneDigits
        }
        // Funciones para rellenar selectores
        this.getCarriers(this.pickupRequest.shipper.id)
        this.getWarehouses(this.pickupRequest.shipper.id)
      }
      // this.$refs.formRenderCreateNormalPickup.updateUIForm()
    },
    onChangeCarrier(carrier) {
      delete this.pickupRequest.warehouse
      if (this.pickupRequest.carrier) {
        this.setCarrierServices()
        this.setRangeTimes()
      } else {
        this.fields = this.fields.filter(field => !['service_type', 'line'].includes(field.name))
      }
      // Se borra la seleccion de rango horario
      delete this.pickupRequest.range_time
      this.setHour(this.pickupRequest.carrier)
      this.setDeliveriesSelect()
    },
    async onChangeWarehouse() {
      this.setWarehouseContacEmail(this.pickupRequest.warehouse)
      this.getSummaries()
      await Promise.all([
        this.getNonWorkingDays(this.pickupRequest.carrier.code),
        this.getCarrierConfig()
      ])
      const carrier = {
        normal_configuration: this.carrierConfig,
        time_zone: this.carrierConfig.time_zone
      }
      this.setHour(carrier)
    },
    onChangeSize() {
      this.setDimensionFields(this.pickupRequest.size)
    },
    onChangePickupDate() {
      delete this.pickupRequest.range_time
      this.setRangeTimes()
      // this.updateCarrierConfig(this.carrierConfig)
    },
    onChangeSummary() {
      if (!this.pickupRequest.summary) return
      this.selectedSummariesList.push(this.pickupRequest.summary)
      this.deliveriesOptions = this.deliveriesOptions.filter(summary => summary.id !== this.pickupRequest.summary.id)
      this.pickupRequest.summary.selected_deliveries_id = this.pickupRequest.summary.deliveries.map(delivery => delivery.id)
      this.pickupRequest.summary.selected_deliveries_ots = this.pickupRequest.summary.deliveries.map(delivery => delivery.tracking_number)
      this.updateDeliveriesCount()
      this.pickupRequest.summary = null
    },
    removeSummaryInList(summary) {
      this.deliveriesOptions.push(summary)
      this.selectedSummariesList = this.selectedSummariesList.filter(el => el.id !== summary.id)
      this.updateDeliveriesCount()
    },
    showSummaryDetails(summary) {
      this.selectedSummary = summary
      // Cambio de vistas
      this.$refs.summaryModal.showModal(this.selectedSummary)
      this.$bvModal.show('modalSummaryDetails')
      this.$bvModal.hide('modalCreateNormalPickup')
    },
    close() {
      this.pickupRequest = {}
      this.fields[this.selectionIndex('contact_phone')].prepend = { text: '+', color: '#BBBBBB' }
      delete this.fields[this.selectionIndex('contact_phone')].max
      // Reseteo del calendario
      this.fields[this.selectionIndex('pickup_date')].propsVCalendar = { 
        'min-date': this.currentDate,
        'max-date': this.maxDate.setDate(this.currentDate.getDate() + this.daysInCalendar)
      }
      this.fields[this.selectionIndex('pickup_date')].disabledDates = { weekdays: [1, 7] }
      this.pickupRequest.size = { id: 'xs', text: 'Sobre' }
      this.fields = this.fields.filter(field => !['height', 'width', 'length', 'description', 'dimensionLine'].includes(field.name))

      this.selectedSummariesList = []
      this.deliveriesOptions = []
      this.display.deliveriesField = false
      this.summariesMsg = null
      
      if (this.mySession.role === this.ROLES.ecommerce || this.mySession.role === this.ROLES.seller) this.setInitialData()
      // this.$refs.formRenderCreateNormalPickup.updateUIForm()
    },
    /**
     * Actualiza las opciones de un campo del tipo FieldSelect
     * @param {string} name Nombre del campo a actualizar sus opciones
     * @param {any[]} options Opciones a actualizar
     */
    setSelectOptions(name, options) {
      const index = this.fields.findIndex(el => el.name === name)
      this.fields[index].options = options
      if (this.fields[index].keyFormRender !== undefined) this.fields[index].keyFormRender++
      else this.keyFormRender++
    },
    /** 
    * Busca el indice de un campo segun su nombre
    */
    selectionIndex(name) {
      return this.fields.findIndex(el => el.name === name)
    },
    ok (e) {
      e.preventDefault()
      this.$refs.formRenderCreateNormalPickup.checkForm()
    },
    createPickup () {
      this.loading.createPickup = true
      const { first_name, last_name, email, role } = this.mySession
      const { 
        shipper, 
        carrier, 
        warehouse, 
        qty, 
        weight, 
        size, 
        pickup_date, 
        range_time, 
        contact_name, 
        contact_phone 
      } = this.pickupRequest

      // Se construye el payload base
      const payload = {
        source: 'platform',
        carrier_code: carrier.code,
        warehouse_code: warehouse.id,
        qty,
        weight,
        pick_up_date: moment(pickup_date).format('YYYY-MM-DD'),
        contact_name,
        contact_phone,
        range_time: range_time.label,
        size: size.id,
        force: 'false',
        user: {
          first_name,
          last_name,
          email,
          role
        }
      }
      // En caso de que el retiro posea servicio de carrier
      if (this.pickupRequest.service_type) {
        payload.service_type = {
          name: this.pickupRequest.service_type.name,
          code: this.pickupRequest.service_type.code
        }
      }
      // Si el size es "c" se agrega custom size
      if (this.pickupRequest.size.id === 'c') {
        payload.height = this.pickupRequest.height
        payload.width = this.pickupRequest.width
        payload.length = this.pickupRequest.length
        payload.description = this.pickupRequest.description
      }
      // Correo contacto bodega 
      if (this.pickupRequest.contact_email) {
        payload.warehouse_contact_email = this.pickupRequest.contact_email
      }
      // Deliveries para carriers que lo necesiten
      if (this.allSelectedOts.length > 0) {
        payload.deliveries = this.allSelectedOts
      }

      this.tabPickupsService.callService('createNormalPickup', payload, { shipper_id: shipper.id }, { fullResponseError: true })
        .then(resp => {
          const timeLimit = resp.data.carrier.config.time_limit
          const text = `<p>Recuerde que para retiros en el mismo día, se tomarán únicamente los envíos en estado <strong>Listo para Despacho - Impreso</strong> agendados <strong>antes de las ${timeLimit} hs.</strong></p>`
          const title = 'Creación de retiro Ok!'
          this.$success(text, null, title)
          this.$emit('updateList', resp.data)
        })
        .catch(error => {
          console.error(error)
          this.$alert(error.data)
        })
        .finally(() => this.loading.createPickup = false)
    },
    /**
     * Obtiene la configuración de retiros normales del carrier según la empresa y
     * lugar de retiro
     */
    getCarrierConfig () {
      const { shipper, carrier, warehouse, service_type} = this.pickupRequest
      const params = {
        carrier_code: carrier.code,
        place_id: warehouse.place_id,
        company_id: shipper.id,
        type: 'normal'
      }

      if (service_type) params.service_type_code = service_type.code

      return this.tabPickupsService.callService('getCarrierConfig', params, params)
        .then(resp => {
          this.carrierConfig = resp.data
          this.updateCarrierConfig(this.carrierConfig)
        })
        .catch(error => console.error(error))
    },
    /**
     * Aplica la configuración del carrier según las condiciones configuradas.
     * Esto puede actualizar los rangos horarios y el horario de corte del formulario
     */
    checkConditions() {
      const {conditions} = this.carrierConfig

      if (!conditions) return

      const params = {
        address: {
          place_id: this.pickupRequest.warehouse.place_id
        },
        length: this.pickupRequest.length,
        width: this.pickupRequest.width,
        height: this.pickupRequest.height,
        weight: this.pickupRequest.weight,
        qty: this.pickupRequest.qty
      }
      const paramKeys = Object.keys(params)
      const paramValues = Object.values(params)
      const validCondition = conditions.find(({ condition }) => {
        const conditionFunc = Function(...paramKeys, `return ${condition}`)
        return conditionFunc(...paramValues)
      })

      if (validCondition) {
        this.carrierConfig = {
          ...this.carrierConfig,
          ...validCondition.config
        }
        this.updateCarrierConfig(this.carrierConfig)
        return
      }

      this.updateCarrierConfig(this.carrierConfig)
    },
    updateCarrierConfig(carrierConfig) {
      // Se rellena el selector "Rango horario" con los rangos horarios del carrier seleccionado
      const rangeTime = carrierConfig.range_time.map((el, index) => ({ 
        ...el,
        id: index,
        text: el.label,
        disabled: this.isRangeDisabled(el)
      }))

      this.$refs.formRenderCreateNormalPickup.setSelectOptions('range_time', rangeTime)
      const selectedRangeTime = this.pickupRequest.range_time
      if (selectedRangeTime) {
        const isValidRangeTime = rangeTime.some(field => field.label === selectedRangeTime.label)
        if (!isValidRangeTime) {
          this.$refs.formRenderCreateNormalPickup.updateField('range_time', null)
        }
      }
      const carrier = {
        normal_configuration: carrierConfig,
        time_zone: carrierConfig.time_zone
      }
      this.setHour(carrier)
    },
    setHour(carrier) {
      const carrierCurrentHour = this.applyTimeZone(carrier.time_zone)
      const configuration = carrier.normal_configuration

      if (configuration.time_limit) {
        const eleventhHour = moment(configuration.time_limit, 'h:mm')
        const pickupDate = moment()
        const daysBefore = configuration.days_before ?? 0

        // Verifica el horario de corte
        if (carrierCurrentHour.isSameOrAfter(eleventhHour)) {
          pickupDate.add(1, 'day')
        }
        // Se setea la fecha mínima del retiro como la fecha actual más los días hábiles de anticipación
        const minPickupDate = this.getDateWithBusinessDaysBefore(pickupDate, daysBefore)
        this.fields[this.selectionIndex('pickup_date')].propsVCalendar['min-date'] = minPickupDate.toDate()
        // Actualizamos la fecha de retiro y la fecha de inicio del calendario, dependiendo de la fecha mínima del retiro
        this.pickupRequest.pickup_date = pickupDate.isBefore(minPickupDate) ? minPickupDate : pickupDate
      }
    },

    /**
     * Retorna la fecha más los días hábiles de anticipación
     * @param {moment} startDate Fecha de inicio
     * @param {number} daysBefore Días hábiles de anticipación
     * @returns {moment} Objeto Moment que representa la fecha resultante
     */
    getDateWithBusinessDaysBefore(startDate, daysBefore) {
      const pickupDateIndex = this.selectionIndex('pickup_date')
      const disabledDates = this.fields[pickupDateIndex].disabledDates
      const today = startDate.clone()
      let businessDays = 0
      while (businessDays < daysBefore) {
        today.add(1, 'days')
        const isDisabled = this.isDateDisabled(today, disabledDates)
        if (!isDisabled) {
          businessDays++
        }
      }
      return today
    },
    isDateDisabled (date, disabledDates) {
      if (Array.isArray(disabledDates)) {
        return disabledDates.some(day => date.day() === (day === 1 ? 7 : day - 1))
      } else if (typeof disabledDates === 'object' && disabledDates.weekdays) {
        return disabledDates.weekdays.includes(date.day() === 1 ? 7 : date.day() - 1)
      } else {
        return false
      }
    },
    updateSummary () {
      this.updateDeliveriesCount()
    },
    updateDeliveriesCount() {
      // Se hace conteo de Ots y se actualiza el campo qty
      this.allSelectedOts = this.selectedSummariesList.flatMap(summary => summary.selected_deliveries_ots)

      // Se actualiza el campo qty, sae saca el campo 'n_packages' 
      // de cada manifiesto seleccionado solo si su OT esta dentro del array 'allSelectedOts'
      this.pickupRequest.qty = this.selectedSummariesList
        .flatMap(summary => summary.deliveries)
        .filter(delivery => this.allSelectedOts.includes(delivery.tracking_number.toString()))
        .reduce((total, delivery) => total + delivery.n_packages, 0)
      // Luego de cambiar el campo 'qty' se actualizan las "conditions"
      this.checkConditions()
    }
  }
}
</script>

<style>

</style>