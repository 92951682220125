<template>
  <div>
    <b-modal id="modalHistoryRequest"
      size="xl"
      :title="$t('Historial de solicitudes')"
      hide-footer
      centered>
        <div class="mt-0">
          <table-render id="pending-request" class="mb-2" :rows="rows" :schema="schema">
            <!-- Usuario -->
            <template #creator_user="scope">
              <span>
                <strong>
                  {{ scope.rowdata.user.first_name }} {{ scope.rowdata.user.last_name }}
                </strong> 
                - {{ scope.rowdata.user.email }}
              </span>
            </template>
            <!-- Usuario enviame -->
            <template #enviame_user="scope">
              <span v-if="scope.rowdata.response">
                <strong>
                  {{ scope.rowdata.response.user.first_name }} {{ scope.rowdata.response.user.last_name }}
                </strong>
                - {{ scope.rowdata.response.user.email }}
              </span>
              <span v-else> -- </span>
            </template>
            <!-- Tipo -->
            <template #type="scope">
              <span>{{ setTypeRequest(scope.rowdata.type) }}</span>
            </template>
            <!-- Estado -->
            <template #status="scope">
              <span :class="`text-${getRequestsStatuses(scope.rowdata.status).color}`">{{ getRequestsStatuses(scope.rowdata.status).label }} </span>
            </template>
          </table-render>
        </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TabPickupsService from '../tabPickups.service'
export default {
  props: ['pickup'],
  data () {
    return {
      rows: [],
      schema: [],
      tabPickupsService: new TabPickupsService(this)
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession'
    })
  },
  watch: {
    mySession () {
      if (this.mySession.id) this.setInitialData()
    },
    'pickup': {
      handler(value) {
        this.rows = value.requests
      },
      deep: true
    }
  },
  mounted () {
    this.getAllInitialData()
  },
  methods: {
    getAllInitialData () {
      if (this.mySession.id) this.setInitialData()
    },
    setInitialData () {
      this.schema = [
        { label: 'Usuario solicitante', key: 'creator_user', useSlot: true },
        { label: 'Usuario Enviame', key: 'enviame_user', useSlot: true },
        { label: 'Fecha', key: 'date' },
        { label: 'Fecha inicio programa', key: 'effective_date' },
        { label: 'Tipo', key: 'type', useSlot: true },
        { label: 'Estado', key: 'status', useSlot: true }
      ]
      if (this.mySession.role !== 'admin') {
        this.schema = this.schema.filter(el => el.key !== 'enviame_user')
      }
    },
    setTypeRequest (type) {
      const requestsTypes = {
        create: 'Creación',
        update: 'Edición',
        disable: 'Desactivación'
      }
      return requestsTypes[type]
    },
    getRequestsStatuses (status) {
      const requestsStatuses = {
        created: { label: 'Creada', color: 'info' },
        approved: { label: 'Aprobada', color: 'success' },
        rejected: { label: 'Rechazada', color: 'danger' },
        pending_marketplace: { label: 'Pendiente marketplace', color: 'warning' },
        rejected_marketplace: { label: 'Rechazado marketplace', color: 'danger' }
      }
      return requestsStatuses[status]
    }
  }
}
</script>

<style>

</style>