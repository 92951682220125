<template>
  <div>
    <b-modal id="modalSummaryDetails"  
      no-close-on-esc
      no-close-on-backdrop
      centered
      hide-header
      hide-footer
      size="lg">
      <div class="mt-0">
        <!-- Titulo -->
        <div class="col-md-12 text-center my-2">
          <div class="custom-heading">
            <strong> {{ modalTitle }} </strong>
          </div>
          <div class="custom-subheading">
            <strong> Desmarque las casillas de los envíos que no serán retirados </strong>
          </div>
        </div>
        <!-- Formulario -->
        <div class="col-md-12">
          <form-render :fields="fields" 
            :form.sync="formFilter" 
            :buttonSend="buttonSend" 
            @send="filterForm" 
            containerButtonsClass="col-sm-12 col-md-5 container-button mt-1">
              <template #buttons>
                <b-button variant="outline-light" 
                  v-b-tooltip.hover 
                  :title="$t('Limpiar filtros')" 
                  class="ml-2" 
                  @click="cleanFilter"><feather-icon icon="RefreshCwIcon"/>
                </b-button> 
              </template>
          </form-render>
        </div>
        <!-- Tabla -->
        <div class="col-md-12 mb-2">
          <table-render :rows="rows" :schema="schema" :selectedRows.sync="selectedRows" showCheckboxes="true"></table-render>
        </div>
        <!-- Alerta -->
        <!-- <div class="col-md-12 mb-1">
          <span class="text-danger">(*)Nota: Los envíos desmarcamos quedaran fuera de un manifiesto.</span>
        </div> -->
        <!-- Botones -->
        <div class="col-md-12">
          <b-button @click="backToCrete" variant="warning">
            Volver
          </b-button>
          <b-button @click="confirmDeliveries" :disabled="loading.confirmDeliveries" class="push-right" variant="success">
            Siguiente
          </b-button>
        </div>
      </div>

    </b-modal>
  </div>
</template>

<script>
export default {
  props: ['summary'],
  data () {
    return {
      rows: [],
      originalRows: [],
      selectedRows: [],
      selectedDeliveries: [],
      schema: [],
      fields: [],
      formFilter: {},
      loading: {
        confirmDeliveries: false
      },
      buttonSend: { icon: 'SearchIcon', color: 'warning', title: 'Filtrar' },
      modalTitle: ''
    }
  },
  // watch: {
  //   summary: {
  //     handler(summary) {
  //       this.modalTitle = `Manifiesto número ${summary.id}`
  //       this.rows = summary.deliveries.map(delivery => {
  //         const checkedDelivery = {
  //           ...delivery,
  //           shipper_name: summary.shipper.name
  //         }
  //         checkedDelivery.checked = summary.selected_deliveries_id.includes(delivery.id)
  //         return checkedDelivery
  //       })
  //       // Se hace una copia del listado original
  //       this.originalRows = [...this.rows]
  //       this.selectedRows = this.rows.filter(delivery => delivery.checked).map(delivery => delivery.id)
  //     },
  //     deep: true
  //   }
  // },
  watch: {
    selectedRows () {
      if (this.selectedRows.length === 0) this.loading.confirmDeliveries = true
      else this.loading.confirmDeliveries = false
    }
  },
  mounted () {
    this.setInitialData()
  },
  methods: {
    showModal(summary) {
      this.modalTitle = `Manifiesto número ${summary.id}`
      this.rows = summary.deliveries.map(delivery => {
        const checkedDelivery = {
          ...delivery,
          shipper_name: summary.shipper.name
        }
        checkedDelivery.checked = summary.selected_deliveries_id.includes(delivery.id)
        return checkedDelivery
      })
      // Se hace una copia del listado original
      this.originalRows = [...this.rows]
      this.selectedRows = this.rows.filter(delivery => delivery.checked).map(delivery => delivery.id)
    },
    setInitialData () {
      this.fields = [
        {
          fieldType: 'FieldInput', 
          name: 'delivery_id',
          type: 'text',
          label: 'N° de envío',
          containerClass: 'col-sm-12 container-info col-md-5'
        }
      ]

      this.schema = [
        { label: 'Seller', key: 'shipper_name' },
        { label: 'N° de envío', key: 'imported_id' },
        { label: 'Orden de flete', key: 'tracking_number' },
        { label: 'Cliente', key: 'client' },
        { label: 'Comuna', key: 'place' }
      ]
    },
    filterForm(form) {
      this.rows = this.originalRows.filter(row => {
        // Con esto el filtro aplica para los imported_id y las OTs
        const importedIdMatch = row.imported_id.includes(form.delivery_id)
        const trackingNumberMatch = row.tracking_number.includes(form.delivery_id)
        return importedIdMatch || trackingNumberMatch
      })
    },
    cleanFilter() {
      this.formFilter = {}
      this.rows = this.originalRows
    },
    backToCrete() {
      this.$bvModal.show('modalCreateNormalPickup')
      this.$bvModal.hide('modalSummaryDetails')
    },
    confirmDeliveries () {
      // Array con IDs de los envíos seleccionados
      this.summary.selected_deliveries_id = this.selectedRows
      // Array con OTs de los envíos seleccionados
      this.summary.selected_deliveries_ots = this.summary.deliveries
        .filter(delivery => this.selectedRows.includes(delivery.id))
        .map(delivery => delivery.tracking_number)
      // Cambio de vistas
      this.$bvModal.show('modalCreateNormalPickup')
      this.$bvModal.hide('modalSummaryDetails')
      // Se actualiza el form de creacion con la carga del manifiesto editado
      this.$emit('updateSummary', this.summary)
    }
  }
}
</script>

<style scoped>
.custom-heading {
  font-size: 24px;
}

.custom-subheading {
  font-size: 18px; 
}
</style>