<template>
  <div>
    <b-modal id="modalActionsRequest"
      size="md"
      no-close-on-esc
      no-close-on-backdrop
      :title="modalData.modalTitle"
      hide-footer
      centered>
        <div class="row">
          <div class="col-md-12">
            <!-- Formulario -->
            <form-render ref="formRenderActionsRequest" 
              class="my-2"
              :fields="fields"
              :form.sync="form"
              :key="keyFormRender"
              @send="confirmAction">
            </form-render>
          </div>
        </div>
        <!-- Botones volver y confirmar -->
        <div class="col-md-12">
          <b-button @click="backToPendingRequest" variant="warning">
            <feather-icon icon="ArrowLeftIcon"/> Volver al listado
          </b-button>
          <b-button @click="ok" :disabled="modalData.btnLoading" class="push-right" :variant="modalData.btnVariant">
            {{ modalData.btnLabel }} 
            <feather-icon v-if="!modalData.btnLoading" :icon="modalData.btnIcon"/>
            <i v-else :class="['fa', 'fa-spinner', 'fa-spin']"/> 
          </b-button>
        </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TabPickupsService from '../tabPickups.service'
export default {
  props: ['currentRequest'],
  data () {
    return {
      modalData: {
        modalTitle: 'Solicitudes pendiente',
        btnLabel: 'Confirmar',
        btnLoading: false,
        btnIcon: '',
        btnVariant: 'success'
      },
      fields: [],
      form: {},
      keyFormRender: 0,
      frequentPickupsDays: [
        { label:'Lu', text: 'Lunes', id:'mon', calendar_day_id: 2 },
        { label:'Ma', text: 'Martes', id:'tue', calendar_day_id: 3 },
        { label:'Mi', text: 'Miércoles', id:'wed', calendar_day_id: 4 },
        { label:'Ju', text: 'Jueves', id:'thu', calendar_day_id: 5 },
        { label:'Vi', text: 'Viernes', id:'fri', calendar_day_id: 6 }
      ],
      REQUEST_ACTIONS:{
        APPROVE: 'approve',
        REJECT: 'reject'
      },
      localCurrentRequest: {},
      tabPickupsService: new TabPickupsService(this)
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession'
    })
  },
  watch: {
    // mySession () {
    //   if (this.mySession.id) this.setInitialData()
    // },
    currentRequest: {
      handler(newVal) {
        this.form = {}
        this.localCurrentRequest = JSON.parse(newVal)
        this.setDataModal() 
      },
      deep: true
    }
  },
  // mounted () {
  //   this.getAllInitialData()
  // },
  methods: {
    // getAllInitialData () {
    //   if (this.mySession.id) this.setInitialData()
    // },
    // setInitialData () {
    //   this.setDataModal()
    // },
    setDataModal () {
      if (this.localCurrentRequest.action === this.REQUEST_ACTIONS.APPROVE) {
        this.modalData = {
          modalTitle: 'Aprobar Solicitud de retiro',
          btnLabel: 'Aprobar Solicitud',
          btnLoading: false,
          btnIcon: 'CheckIcon',
          btnVariant: 'success'
        }
      } else if (this.localCurrentRequest.action === this.REQUEST_ACTIONS.REJECT) {
        this.modalData = {
          modalTitle: 'Rechazar Solicitud de retiro',
          btnLabel: 'Rechazar Solicitud',
          btnLoading: false,
          btnIcon: 'XIcon',
          btnVariant: 'danger'
        }
      }
      this.setFields()
    },
    setFields () {
      if (this.localCurrentRequest.action === this.REQUEST_ACTIONS.APPROVE) {
        this.fields = [
          {
            fieldType: 'FieldInput', 
            name: 'carrier_pickup_number',
            type: 'number',
            label: 'Folio courier (opcional)',
            clearable: true,
            containerClass: 'col-sm-12 container-info col-md-12'
          },
          { 
            fieldType: 'FieldDatepicker', 
            label: 'Fecha inicio de programa', 
            name: 'start_date',
            propsVCalendar: { 
              'min-date': new Date()
            },
            disabledDates: { weekdays: [1, 7] },
            validation: 'requiredDate',
            containerClass: 'col-sm-12 container-info col-md-12'
          }
        ]
        // Ajuste de los días del calendario "start_date"
        const selected_pickups_calendar_id = this.parseDays(this.localCurrentRequest.pickup_days).map(day => day.calendar_day_id)
        const allDays = [1, 2, 3, 4, 5, 6, 7]
        const unselectedDays = allDays.filter(day => !selected_pickups_calendar_id.includes(day))
        this.fields[this.selectionIndex('start_date')].disabledDates = { weekdays: selected_pickups_calendar_id.length > 0 ? unselectedDays : [1, 7] }

      } else if (this.localCurrentRequest.action === this.REQUEST_ACTIONS.REJECT) {
        this.fields = [
          {
            fieldType: 'FieldInput', 
            name: 'reason',
            type: 'text',
            label: 'Motivo',
            clearable: true,
            validation: 'required',
            containerClass: 'col-sm-12 container-info col-md-12'
          }
        ]
      }
      // this.keyFormRender++
    },
    /**
     * Función que se encarga de parsear los días de un string a un arreglo de objetos.
     * @param {Array} days días (abreviado) separados por coma ejm: "mon,tue,wed"
     * @returns {Array} retorna un arreglo de objetos con los días.
     */
    parseDays(days) {
      const parsed_days = []
      days.forEach(day => {
        switch (day) {
        case 'mon':
          parsed_days.push({ label: 'Lu', text: 'Lunes', id:'mon', calendar_day_id: 2 })
          break
        case 'tue':
          parsed_days.push({ label: 'Ma', text: 'Martes', id:'tue', calendar_day_id: 3 })
          break
        case 'wed':
          parsed_days.push({ label: 'Mi', text: 'Miércoles', id:'wed', calendar_day_id: 4 })
          break
        case 'thu':
          parsed_days.push({ label: 'Ju', text: 'Jueves', id:'thu', calendar_day_id: 5 })
          break
        case 'fri':
          parsed_days.push({ label: 'Vi', text: 'Viernes', id:'fri', calendar_day_id: 6 })
          break
        case 'sat':
          parsed_days.push({ label: 'Sa', text: 'Sabado', id:'sat', calendar_day_id: 7 })
          break
        case 'sun':
          parsed_days.push({ label: 'Do', text: 'Domingo', id:'sun', calendar_day_id: 1 })
          break
        }
      })
      return parsed_days
    },
    /** 
    * Busca el indice de un campo segun su nombre
    */
    selectionIndex(name) {
      return this.fields.findIndex(el => el.name === name)
    },
    backToPendingRequest () {
      this.$bvModal.show('modalPendingRequest')
      this.$bvModal.hide('modalActionsRequest')
    },
    ok (e) {
      e.preventDefault()
      this.$refs.formRenderActionsRequest.checkForm()
    },
    confirmAction (data) {
      const { first_name, last_name, email, role } = this.mySession
      const payload = {
        type: this.localCurrentRequest.action,
        user: {
          first_name,
          last_name,
          email,
          role
        }
      }
      // Se añaden los query params
      if (data.reason) payload.reason = data.reason
      if (data.carrier_pickup_number) payload.carrier_pickup_number = data.carrier_pickup_number
      if (data.start_date) payload.pickup_start_date = this.$options.filters.moment(data.start_date, 'YYYY-MM-DD')
      
      const params = {
        shipper_id: this.localCurrentRequest.company.id,
        identifier: this.localCurrentRequest.identifier,
        requests_id: this.localCurrentRequest.last_request.id
      }
      this.modalData.btnLoading = true
      this.tabPickupsService.callService('approveOrRejectFrequentPickup', payload, params)
        .then(resp => {
          this.$success('Solicitud actualizada correctamente.')
          this.$bvModal.hide('modalActionsRequest')
          this.$emit('updatePendingRequest')
        })
        .catch(err => {
          this.$alert('Ha ocurrido un error inesperado.')
        })
        .finally(() => this.modalData.btnLoading = false)
    }
  }
}
</script>

<style lang="scss">
  .vc-popover-content-wrapper{
    z-index: 1000 !important;
  }
</style>