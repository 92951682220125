<template>
  <div>
    <!-- Tabla retiros frecuentes -->
    <div class="table-render-skeleton" v-if="loading.first">
      <b-skeleton-table
        :rows="pagination.limit || 10"
        :columns="schema.length || 10"
        :table-props="{}"
      />
    </div>
    <table-render v-if="!loading.first" id="normal-pickups" :rows="rows" :schema="schema" :loading="pagination.loading" :key="keyTableRender">
      <!-- Compañia -->
      <template #company="scope">
        <span>{{ scope.rowdata.company.name }} - <strong>{{ scope.rowdata.company.id }}</strong></span>
      </template>
      <!-- Días de retiro -->
      <template #pickup_days="scope">
        <span>{{ scope.rowdata.format_pickup_days }}</span>
      </template>
      <!-- Dirección -->
      <template #address="scope">
        <span v-if="scope.rowdata.address">{{ scope.rowdata.address.full_address }}, <strong>{{ scope.rowdata.address.place }}</strong></span>
      </template>
      <!-- Contacto -->
      <template #contact="scope">
        <b-button v-if="scope.rowdata.contact" v-b-tooltip.hover.html="$generateTooltipPerson(scope.rowdata.contact)" variant="link " >{{scope.rowdata.contact.name}}</b-button>
      </template>
      <!-- Estado -->
      <template #status="scope">
        <div class="d-flex">
          <span class="table_dot--state" :class="getStatusClass(scope.rowdata.status.code)"></span>
          <span class="ml-1">{{ scope.rowdata.status.name }}</span>
        </div>
      </template>

      <!-- Acciones -->
      <template #pickup_actions="scope">
        <div class="text-center">
          <!-- Ver detalle de retiro -->
          <a class="mr-1 text-primary" @click="showPickupDetails(scope.rowdata)" v-b-tooltip.hover :title="$t('Ver detalles')"><feather-icon icon="EyeIcon"/></a>
          <!-- Ver historial de solicitudes -->
          <a class="mr-1 text-primary" @click="showHistoryRequest(scope.rowdata)" v-b-tooltip.hover :title="$t('Historial solicitudes')"><feather-icon icon="AlignCenterIcon"/></a>
          <!-- Editar retiro frecuente -->
          <a class="mr-1 text-primary" v-if="scope.rowdata.status.code === 'accepted'" @click="showEditPickup(scope.rowdata)" v-b-tooltip.hover :title="$t('Editar retiro')"><feather-icon icon="Edit2Icon"/></a>
          <!-- Desactivar retiro frecuente -->
          <a class="mr-1 text-danger" v-if="scope.rowdata.status.code === 'accepted'" @click="showDisableFrequentModal(scope.rowdata)" v-b-tooltip.hover :title="$t('Desactivar retiro')"><feather-icon icon="SlashIcon"/></a>
          <!-- Ver solicitudes pendientes -->
          <a class="mr-1 text-primary" v-if="mySession.role === roles.admin && scope.rowdata.last_request.status === 'created'" @click="openPendingRequestModal(scope.rowdata)" v-b-tooltip.hover :title="$t('Ver solicitudes pendientes')"><feather-icon icon="FileTextIcon"/></a>
        </div>
      </template>
    </table-render>
    <!-- Paginación retiros frecuentes -->
    <pagination v-if="!loading.first" :pagination="pagination" :noDigits="true" :showSize="true"/>

    <!-- Componentes -->
    <!-- <modal-frequent-pickup-details :pickup="currentPickup.details"></modal-frequent-pickup-details> -->
    <modal-disable-frequent :pickup="currentPickup.disableFrequent" @updatePickupStatus="updatePickupStatus"></modal-disable-frequent>
    <!-- Modal historial de solicitudes -->
    <modal-history-request :pickup="currentPickup.historyRequest"></modal-history-request>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import * as moment from 'moment'
import TabPickupsService from '../tabPickups.service'
// Componentes
import ModalDisableFrequent from './ModalDisableFrequent'
// import ModalFrequentPickupDetails from './ModalFrequentPickupDetails.vue'
import ModalHistoryRequest from './ModalHistoryRequest.vue'
export default {
  name: 'frequent-pickups-table',
  components: { ModalDisableFrequent, ModalHistoryRequest },
  props: ['filters', 'updateList', 'currentTab'],
  data() {
    return {
      rows: [],
      schema: [],
      keyTableRender: 0,
      localFilters: {},
      loading: {
        first: true
      },
      currentPickup: {
        details: {},
        disableFrequent: {},
        historyRequest: {}
      },
      initialDateRange: {
        start: moment().add(-15, 'days').toDate(),
        end: moment().add(3, 'days').toDate()
      },
      conditionsByRole: {},
      conditionsByPermissions: {},
      frequentPickupsDaysDefault: [
        { label:'Lu', name: 'Lunes', value: 'mon' },
        { label:'Ma', name: 'Martes', value: 'tue' },
        { label:'Mi', name: 'Miércoles', value: 'wed' },
        { label:'Ju', name: 'Jueves', value: 'thu' },
        { label:'Vi', name: 'Viernes', value: 'fri' },
        { label:'Sa', name: 'Sabado', value: 'sat' }
      ],
      pagination: { page: 1, limit: 20, total: 20, loading: false },
      tabPickupsService: new TabPickupsService(this),
      roles:{
        marketplace: 'marketplace',
        ecommerce: 'ecommerce',
        seller: 'seller',
        admin: 'admin'
      }
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession'
    })
  },
  watch: {
    mySession () {
      if (this.mySession.id) this.setInitialData()
    },
    filters: {
      handler(newFilters) {
        if (this.currentTab === 1) this.handleFiltersChange(newFilters)
      },
      deep: true
    },
    updateList: {
      handler(newPickup) {
        newPickup.format_pickup_days = newPickup.pickup_days.map(day => this.frequentPickupsDaysDefault.find(dia => dia.value === day).name).join(', ')
        this.setPickupInTable(newPickup)
      },
      deep: true
    },
    'pagination.page' () {
      this.getPickups(this.filters)
    },
    'pagination.limit' () {
      this.getPickups(this.filters)
    }
  },
  mounted () {
    this.getAllInitialData()
  },
  methods: {
    getAllInitialData () {
      if (this.mySession.id) this.setInitialData()
    },
    setInitialData () {
      this.loading.first = false
      this.schema = [
        { label: 'Seller - ID', key: 'company', useSlot: true },
        { label: 'Días de retiro', useSlot: true, key: 'pickup_days' },
        { label: 'Rango horario', key: 'range_time' },
        { label: 'Envíos', key: 'qty' },
        { label: 'Dirección', key: 'address', useSlot: true },
        { label: 'Courier', key: 'carrier.name' },
        { label: 'N° de retiro', key: 'identifier' },
        { label: 'Contacto', key: 'contact', useSlot: true },
        { label: 'Estado', key: 'status', useSlot: true },
        { label: 'Acciones', key: 'pickup_actions', useSlot: true }
      ]
      this.localFilters = {
        pickup_date_from: this.$options.filters.moment(this.initialDateRange.start, 'YYYY-MM-DD'),
        pickup_date_to: this.$options.filters.moment(this.initialDateRange.end, 'YYYY-MM-DD'),
        type: 'normal,normal_frequent'
      }
      this.getPickups(this.localFilters)
      // Objeto con condiciones según el rol del usuario
      this.conditionsByRole = this.tabPickupsService.conditionsByRole(this.mySession.role)
      this.conditionsByPermissions = this.tabPickupsService.conditionsByPermissions(this.mySession)
    },
    handleFiltersChange(newFilters) {
      if (newFilters.clear_filters) {
        this.pagination.page = 1
      }
      this.getPickups(newFilters)
    },
    getStatusClass(statusCode) {
      const statusClassMap = {
        requested_marketplace: 'table_dot--status-requested_marketplace',
        accepted: 'table_dot--status-accepted',
        disabled: 'table_dot--status-disabled',
        rejected: 'table_dot--status-rejected',
        update_pending: 'table_dot--status-update_pending'
      }
      return statusClassMap[statusCode] || 'table_dot--status-accepted'
    },
    showPickupDetails (pickup) {
      // this.currentPickup.details = pickup
      // this.$bvModal.show('modalFrequentPickupDetails')
      pickup.canReturnToList = false
      this.$emit('show-details', pickup)
    },
    showHistoryRequest (pickup) {
      this.currentPickup.historyRequest = pickup
      this.$bvModal.show('modalHistoryRequest')
    },
    showEditPickup (pickup) {
      this.$emit('editFrequentPickup', pickup)
    },
    showDisableFrequentModal (pickup) {
      this.currentPickup.disableFrequent = pickup
      this.$bvModal.show('modalDisableFrequent')
    },
    openPendingRequestModal(pickup) {
      this.$emit('show-pending-request', pickup)
    },
    updatePickupStatus (data) {
      const index = this.findIndexPickup(data.identifier)
      if (index !== -1) {
        this.rows[index] = { ...this.rows[index], ...data }
        this.keyTableRender++
      } else {
        this.getPickups(this.filters)
      }
    },
    findIndexPickup(identifier) {
      return this.rows.findIndex(pickup => pickup.identifier === identifier)
    },
    getPickups (filters) {
      if (this.currentTab !== 1) return
      // Loading de la tabla y limpiar parametro clear filters
      this.pagination.loading = true
      delete this.filters.clear_filters
      const payload = {
        old: true,
        limit: this.pagination.limit, 
        page: this.pagination.page,
        ...filters
      }
      if (this.mySession.role === this.roles.admin) {
        payload.pickup_admin = true
      }
      this.tabPickupsService.callService('getFrequentPickups', payload)
        .then(resp => {
          this.rows = resp.data.map(pickup => {
            return { 
              ...pickup, 
              format_pickup_days: pickup.pickup_days.map(day => this.frequentPickupsDaysDefault.find(dia => dia.value === day).name).join(', ')
            }
          })
        })
        .catch(err => {
          // this.$alert(this.$t('msg-problema-cargar-datos', {code: err}))
        })
        .finally(() => {
          this.pagination.loading = false
        })
    },
    setPickupInTable(newPickup) {
      this.rows.unshift(newPickup)
    }
  }
}
</script>

<style>
.table_dot--state{
  position: relative;
  display: inline-block;
  top: 3px;
  height: 15px;
  width: 15px;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
}

.table_dot--status-requested_marketplace {
  background-color:rgb(77, 152, 213)
}

.table_dot--status-accepted {
  background-color: #55D6A7;
}

.table_dot--status-disabled {
  background-color: rgb(214, 85, 94)
}
.table_dot--status-rejected {
  background-color: rgb(223, 38, 50)
}
.table_dot--status-update_pending {
  background-color: rgb(233, 222, 3)
}
</style>