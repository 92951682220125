<template lang="">
  <b-modal
    id="CuttingScheduleModalID"
    title="Horarios de corte de retiros"
    size="lg"
    title-class="h1"
    @show="clearVariables"
    hide-footer>
    <b-card>
      <h1 class="h4">Importante:</h1>
      <p>
        En caso que no se efectúe el retiro, es importante que durante la mañana
        del día siguiente nos informen a través del chat y además volver a
        agendar el retiro.
      </p>
      <p>
        Retiros para la mañana de Blue Express se deben de solicitar con fecha
        anterior al retiro. No se tomarán en cuenta si la solicitud fue creada
        el mismo día del retiro.
      </p>
      <p>
        Todos los retiros de Starken que tengan como origen en regiones distinto
        a RM se debe completar el archivo "Input" y enviarlo al correo
        retiros@enviame.io o por medio del chat. Se debe enviar antes del
        horario de corte y considerar que se concreta según matriz de transito
        en un plazo aproximado de 48 horas.
        <a href="https://storage.googleapis.com/pucon-misc/INPUT_SOLICITUD_REGION_2022.xlsx">
          Descargar Input acá
        </a>
      </p>
      <form-render :form.sync="form" :fields="fieldsForm"/>
      <div class="table--render--cutting">
        <b-overlay :show="show" rounded="sm" variant="white">
          <table-render :schema="schema" :rows="carrierShow" :fixed="true" :stickyHeader="true"/>
        </b-overlay>
      </div>
    </b-card>
  </b-modal>
</template>
<script>
import BaseServices from '@/store/services/index'
export default {
  data() {
    return {
      baseService: new BaseServices(this),
      countryArray: [],
      timeLimits: [],
      form: {},
      fieldsForm: [],
      carrierArray: [],
      schema: [],
      carrierShow: [],
      pickupArray: [],
      show: true
    }
  },
  watch: {
    'form.country': {
      handler() {
        this.getCarrierTimeLimits()
      },
      deep: true
    },
    'form.pickup_type': {
      handler(value, oldValue) {
        if (!!value && value !== oldValue) {
          this.getCarrierTimeLimits()
        }
      },
      deep: true
    },
    'form.carrier_code': {
      handler() {
        this.setCarriersShow()
      }
    }
  },
  methods: {
    clearVariables() {
      this.form = {}
      this.getAllData()
    },
    getAllData() {
      const queryParamsTime = {
        pickup_type: 'normal'
      }
      const arrServices = [
        { name: 'getCountriesToronto'},
        { name: 'getCarrierTimeLimits',
          queryParams: queryParamsTime
        }
      ]
      this.baseService.callMultipleServices(arrServices, true)
        .then(response => {
          this.countryArray = this.setCountryArray(response.getCountriesToronto.data)
          this.timeLimits = this.setTimeLimits(response.getCarrierTimeLimits.data)
          this.setCarriersShow()
        })
        .finally(() => {
          this.setValuesDefaultForm()
          this.setDataForm()
        })
    },
    getCarrierTimeLimits() {
      if (!!this.form && (!!this.form.country || !!this.form.pickup_type)) {
        const queryParams = {}
        this.show = true
        if (!!this.form.country) queryParams.country = this.form.country.value
        if (!!this.form.pickup_type) queryParams.pickup_type = this.form.pickup_type.value
        this.baseService.callService('getCarrierTimeLimits', queryParams)
          .then(response => {
            this.timeLimits = !!response && !!response.data ? response.data : []
            this.setCarriersShow()
          })
          .finally(() => {
            this.setDataForm()
          })
      }
    },
    setCountryArray(array) {
      return array ? array.map((element) => ({
        value: element.code,
        text: element.name
      })) : []
    },
    setTimeLimits(array) {
      return array ? array : []
    },
    setArrayCarrier() {
      const array = this.timeLimits.map((element) => ({
        value: element.carrier_code,
        text: element.carrier_name
      }))
      return this.$getUniqueList(array, 'text')
    },
    setCarriersShow() {
      this.carrierShow = !!this.form.carrier_code ? this.timeLimits.filter(
        (element) => element.carrier_name === this.form.carrier_code.text
      ) : this.timeLimits
      this.carrierArray = this.setArrayCarrier()
    },
    setValuesDefaultForm() {
      this.pickupArray = [
        { 
          value: 'normal',
          text: 'Normal'
        },
        {
          value: 'return',
          text: 'Devolución'
        }
      ]
      this.$set(this.form, 'pickup_type', this.pickupArray[0])
    },
    setDataForm() {
      this.fieldsForm = [
        { 
          fieldType: 'FieldSelect',
          name: 'country',
          label: 'País',
          containerClass: 'col-sm-12 container-info col-md-4',
          options: this.countryArray,
          mainColor: 'black'
        },
        {
          fieldType: 'FieldSelect',
          name: 'carrier_code',
          label: 'Carrier',
          containerClass: 'col-sm-12 container-info col-md-4',
          options: this.carrierArray,
          mainColor: 'black'
        },
        { 
          fieldType: 'FieldSelect',
          name: 'pickup_type',
          label: 'Tipo de retiro',
          containerClass: 'col-sm-12 container-info col-md-4',
          options: this.pickupArray,
          mainColor: 'black',
          clearable: false
        }
      ]
      this.schema = [
        {label: 'Courier', key: 'carrier_name', sortable: true},
        {label: 'Horario de corte', key: 'time_limit', sortable: true}
      ]
      this.show = false
    }
  }
}
</script>
<style lang="scss">
  .table--render--cutting {
    // max-height: 50vh;
    overflow-y: scroll;

    .table-render-skeleton {
      margin-left: 0;
      margin-right: 0;
    }
  }
</style>
