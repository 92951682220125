<template>
  <div>
    <!-- Tabla retiros normales -->
    <div class="table-render-skeleton" v-if="loading.first">
      <b-skeleton-table
      :rows="pagination.limit || 10"
      :columns="schema.length || 10"
      :table-props="{}"
      />
    </div>
    <table-render v-if="!loading.first" id="normal-pickups" :rows="rows" :schema="schema" :loading="pagination.loading" :key="keyTableRender">
      <!-- Compañia -->
      <template #company="scope">
        <span>{{ scope.rowdata.company.name }} - <strong>{{ scope.rowdata.company.id }}</strong></span>
      </template>
      <!-- Dirección -->
      <template #address="scope">
        <span v-if="scope.rowdata.address">{{ scope.rowdata.address.full_address }}, <strong>{{ scope.rowdata.address.place }}</strong></span>
      </template>
      <!-- Contacto -->
      <template #contact="scope">
        <b-button v-if="scope.rowdata.contact" v-b-tooltip.hover.html="$generateTooltipPerson(scope.rowdata.contact)" variant="link " >{{scope.rowdata.contact.name}}</b-button>
      </template>
      <!-- Estado -->
      <template #status="scope">
        <div class="d-flex">
          <span class="table_dot--state" :class="getStatusClass(scope.rowdata.status.code)"></span>
          <span class="ml-1">{{ scope.rowdata.status.name }}</span>
        </div>
      </template>
      <!-- Acciones -->
      <template #pickup_actions="scope">
        <div class="text-center">
          <!-- Ver detalle de retiro -->
          <a class="mr-1 text-primary" @click="showPickupDetails(scope.rowdata)" v-b-tooltip.hover :title="$t('Ver detalles')"><feather-icon icon="EyeIcon"/></a>
          <!-- Cancelar retiro normal -->
          <a class="mr-1 text-danger" v-if="isCancelable(scope.rowdata) && scope.rowdata.type.code === 'normal' && scope.rowdata.status.code !== 'canceled'" @click="showConfirmCancelPickup(scope.rowdata)" v-b-tooltip.hover :title="$t('Cancelar retiro')"><feather-icon icon="XCircleIcon"/></a>     
          <!-- Evaluar retiro -->
          <div>
            <b-button v-if="scope.rowdata.need_evaluation && scope.rowdata.status.code != 'canceled'" variant="light" size="sm" class="mr-1" @click="showReviewPickup(scope.rowdata)">
              <span v-b-tooltip.hover placement="bottom" title="Evaluar retiro">
                Evaluar
              </span>
            </b-button>
            <b-button v-if="!scope.rowdata.need_evaluation && !scope.rowdata.evaluation && scope.rowdata.status.code != 'canceled'" variant="secondary" size="sm" class="mr-1" @click="showReviewPickup(scope.rowdata)">
              <span v-b-tooltip.hover :title="`Evaluable a partir de ${scope.rowdata.format_pickup_date} a las 20:00 Hrs`">
                Evaluar
              </span>
            </b-button>
          </div>
        </div>
      </template>
    </table-render>
    <!-- Paginación retiros normales -->
    <pagination v-if="!loading.first" :pagination="pagination" :noDigits="true" :showSize="true"/>

    <!-- Componentes -->
    <!-- Detalle de retiro -->
    <modal-pickup-details :pickup="currentPickup.details"></modal-pickup-details>
    <!-- Cancelación de retiro -->
    <modal-cancel-pickup :pickup="currentPickup.cancel" @updatePickupStatus="updatePickupStatus"></modal-cancel-pickup>
    <modal-review-pickup-vue :pickup="currentPickup.evaluate" @updatePickupStatus="updatePickupStatus"></modal-review-pickup-vue>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import * as moment from 'moment'
import TabPickupsService from '../tabPickups.service'
// Componentes
import ModalPickupDetails from './ModalPickupDetails.vue'
import ModalCancelPickup from './ModalCancelPickup.vue'
import ModalReviewPickupVue from './ModalReviewPickup.vue'

export default {
  name: 'normal-pickups-table',
  components: { ModalPickupDetails, ModalCancelPickup, ModalReviewPickupVue },
  props: ['filters', 'updateList', 'currentTab'],
  data () {
    return {
      rows: [],
      schema: [],
      keyTableRender: 0,
      localFilters: {},
      loading: {
        first: true
      },
      currentPickup: {
        details: {},
        cancel: {},
        evaluate: {}
      },
      // dataPickup: {},
      initialDateRange: {
        start: moment().add(-15, 'days').toDate(),
        end: moment().add(3, 'days').toDate()
      },
      pagination: { page: 1, limit: 20, total: 20, loading: false },
      tabPickupsService: new TabPickupsService(this)
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession'
    })
  },
  watch: {
    mySession () {
      if (this.mySession.id) this.setInitialData()
    },
    filters: {
      handler(newFilters) {
        if (this.currentTab === 0) this.handleFiltersChange(newFilters)
      },
      deep: true
    },
    updateList: {
      handler(newPickup) {
        this.setPickupInTable(newPickup)
      },
      deep: true
    },
    'pagination.page' () {
      this.getPickups(this.filters)
    },
    'pagination.limit' () {
      this.getPickups(this.filters)
    }
  },
  mounted () {
    this.getAllInitialData()
  },
  methods: {
    getAllInitialData () {
      if (this.mySession.id) this.setInitialData()
    },
    setInitialData () {
      this.loading.first = false
      this.schema = [
        { label: 'Seller - ID', key: 'company', useSlot: true },
        { label: 'Fecha de retiro', sortable: true, key: 'pickup_date' },
        { label: 'Rango horario', key: 'range_time' },
        { label: 'Envíos', key: 'qty' },
        { label: 'Dirección', key: 'address', useSlot: true },
        { label: 'Courier', key: 'carrier.name' },
        { label: 'N° de retiro', key: 'identifier' },
        { label: 'Contacto', key: 'contact', useSlot: true },
        { label: 'Tipo de retiro', key: 'type.name' },
        { label: 'Estado courier', key: 'status_carrier.code' },
        { label: 'Estado', key: 'status', useSlot: true },
        { label: 'Acciones', key: 'pickup_actions', useSlot: true }
      ]
      this.localFilters = {
        pickup_date_from: this.$options.filters.moment(this.initialDateRange.start, 'YYYY-MM-DD'),
        pickup_date_to: this.$options.filters.moment(this.initialDateRange.end, 'YYYY-MM-DD'),
        type: 'normal,normal_frequent'
      }
      this.getPickups(this.localFilters)
    },
    handleFiltersChange(newFilters) {
      if (newFilters.clear_filters) {
        this.pagination.page = 1
      }
      this.getPickups(newFilters)
    },
    getStatusClass(statusCode) {
      const statusClassMap = {
        scheduled: 'table_dot--status-scheduled',
        done: 'table_dot--status-done',
        failed: 'table_dot--status-failed',
        canceled: 'table_dot--status-canceled'
      }
      return statusClassMap[statusCode] || 'table_dot--status-done'
    },
    getPickups (filters) {
      if (this.currentTab !== 0) return
      // Loading de la tabla y limpiar parametro clear filters
      this.pagination.loading = true
      delete this.filters.clear_filters
      const payload = {
        old: true,
        limit: this.pagination.limit, 
        page: this.pagination.page,
        ...filters
      }
      this.tabPickupsService.callService('getPickups', payload)
        .then(resp => {
          resp.data = resp.data.map(pickup => ({ ...pickup, format_pickup_date: this.$options.filters.moment(pickup.pickup_date, 'DD-MM-YYYY')}))
          this.rows = resp.data
        })
        .catch(err => {
          // this.$alert(this.$t('msg-problema-cargar-datos', {code: err}))
        })
        .finally(() => {
          this.pagination.loading = false
        })
    },
    findIndexPickup(identifier) {
      return this.rows.findIndex(pickup => pickup.identifier === identifier)
    },
    /**
    * Función que verifica si el retiro es cancelable o no.
    * @param {Obj} pickup datos del retiro
    */
    isCancelable(pickup) {
      const maxDate = moment(pickup.max_cancelation_date, 'YYYY-MM-DD HH:mm:ss').utc(true)
      const currentDate = moment.utc()
      const duration = moment.duration(maxDate.diff(currentDate))

      return duration >= 0
    },
    showPickupDetails (pickup) {
      this.currentPickup.details = pickup
      this.$bvModal.show('modalNormalPickupDetails')
    },
    showReviewPickup(pickup) {
      if (pickup.need_evaluation) {
        this.currentPickup.evaluate = pickup
        this.$bvModal.show('ModalReviewPickupID')
      } else {
        const text = `No es posible evaluar el retiro ya que aún no se llega a la fecha y hora de evaluación - <b>${pickup.format_pickup_date} a las 20:00 Hrs</b>.`
        this.$info(text, null, '¡Evaluación pendiente!')
      }
    },
    showConfirmCancelPickup (pickup) {
      this.currentPickup.cancel = pickup
      this.$bvModal.show('modalCancelPickup')
    },
    updatePickupStatus (data) {
      const index = this.findIndexPickup(data.identifier)
      if (index !== -1) {
        this.rows[index] = { ...this.rows[index], ...data }
        this.keyTableRender++
      } else {
        this.getPickups(this.filters)
      }
      if (data.update_evaluation_count) this.$emit('updateCount', data)
    },
    setPickupInTable(newPickup) {
      this.rows.unshift(newPickup)
    }
  }
}
</script>

<style>
.table_dot--state{
  position: relative;
  display: inline-block;
  top: 3px;
  height: 15px;
  width: 15px;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
}

.table_dot--status-scheduled {
  background-color:rgb(77, 152, 213)
}

.table_dot--status-done {
  background-color: #55D6A7;
}

.table_dot--status-failed {
  background-color: rgb(214, 85, 94)
}
.table_dot--status-canceled {
  background-color: rgb(223, 38, 50)
}
</style>