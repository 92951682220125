<template>
  <div>
    <b-card>

      <div class="col-md-12 mb-1">
        <b-button v-if="!loading.first" @click="openModalCreatePickup" class="push-right col-md-2" variant="warning">
          <feather-icon icon="FileTextIcon"/>
          {{$t(create.btnText)}} 
        </b-button>
      </div>

      <b-tabs content-class="mt-05" @input="handleTabChange">
        <b-tab active class="no-body">
          <template #title><feather-icon icon="FileTextIcon"/> Retiros por día </template>
          <!-- Formulario de filtros -->
          <div class="mt-2">
            <pickup-filters v-if="pickupTab === 0" :currentTab="pickupTab" :evaluateFilters="filters" @updateFilters="filterPickups"></pickup-filters>
            <!-- Alerta canales de comunicación -->
            <div class="col-md-12">
              <b-skeleton v-if="loading.first && !loading.evaluateAlert" type="input" class="col-md-4 mt-2 mb-2 spacing-label-field" height="60px"/>
              <b-alert class="p-1 col-sm-12 col-md-6 col-lg-4" variant="primary" show v-if="loading.evaluateAlert && !loading.first && needEvaluationPickupsCount > 0">
                <div class="d-flex justify-content-center align-items-center">
                  <feather-icon size="50" class="mr-50" icon="InfoIcon"/>
                  <span>
                    Ahora puedes encontrar los retiros por evaluar filtrando con el checkbox arriba de la tabla.
                  </span>
                </div>
              </b-alert>
            </div>
            <!-- Alerta canales de comunicación -->
            <!-- <div class="col-md-12">
              <b-alert class="p-1 col-md-6 col-sm-12" variant="warning" show v-if="loading.evaluateAlert && !loading.first && needEvaluationPickupsCount > 0">
                <div class="d-flex justify-content-center align-items-center">
                  <feather-icon size="45" class="mr-50" icon="InfoIcon"/>
                  <span>
                    Hemos notado que tienes <span class="text-danger"> {{ needEvaluationPickupsCount }} </span> retiros sin evaluar, es importante que lleve sus evaluaciones al
                    día para evitar algún inconveniente al momento de agendar nuevos retiros. <a class="lnk lnk-primary" @click="filterNeedEvaluationPickups">Ver retiros con evaluación pendiente</a>.
                  </span>
                </div>
              </b-alert>
            </div> -->
            <!-- Botones retiros normales -->
            <div v-if="!loading.first" class="mt-2 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-between align-items-center">
              <!-- Filtro retiros por evaluar -->
              <b-form-checkbox 
                :checked="needEvaluationPickupsFilter" 
                v-model="needEvaluationPickupsFilter" 
                @change="filterNeedEvaluationPickups()" switch>
                  <span style="font-size: 14px">
                    {{$t('Retiros diarios por evaluar')}} <b-badge pill variant="warning">{{ needEvaluationPickupsCount }}</b-badge>
                  </span>
              </b-form-checkbox>
              <!-- Exportar retiros normales -->
              <b-button @click="downloadExcel()" :disabled="loading.excel" class="push-right" variant="success">
                <feather-icon v-if="!loading.excel" icon="DownloadIcon"/>
                <i v-else :class="['fa', 'fa-spinner', 'fa-spin']"/> 
                {{$t('Exportar retiros diarios')}}
              </b-button>
            </div>
          </div>
          <!-- Tabla retiros normales -->
          <div class="mt-2 mb-2">
            <normal-pickups-table :filters="filters" :updateList="create.normalPickup" :currentTab="pickupTab" @updateCount="updateCount"></normal-pickups-table>
            <!-- @updatePagination="updatePagination" -->
          </div>
        </b-tab>
        <b-tab class="no-body" >
          <template #title><feather-icon icon="FileTextIcon"/> Programas frecuentes</template>
          <!-- Formulario de filtros -->
          <div class="mt-2" >
            <pickup-filters v-if="pickupTab === 1" :currentTab="pickupTab" @updateFilters="filterPickups"></pickup-filters>
            <!-- Botones retiros Frecuentes -->
            <div v-if="!loading.first" class="mt-2 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-end">
              <!-- Exportar retiros Frecuentes -->
              <b-button @click="downloadExcel('frequent')" :disabled="loading.excel" class="push-right" variant="success">
                <feather-icon v-if="!loading.excel" icon="DownloadIcon"/>
                <i v-else :class="['fa', 'fa-spinner', 'fa-spin']"/> 
                {{$t('Exportar retiros frecuentes')}}
              </b-button>
              <!-- Modal Solicitudes pendientes -->
              <b-button v-if="conditionsByRole.show_pending_request || conditionsByPermissions.frequent_marketplace_supervisor" @click="openPendingRequestModal" class="push-right ml-1 position-relative" variant="outline-success">
                <feather-icon icon="FileTextIcon"/>
                {{$t('Solicitudes pendientes')}}
                <span class="count-pending-request" v-if="countPendingRequest"> {{ countPendingRequest }} </span>
              </b-button>
            </div>
          </div>
          <!-- Tabla retiros normales -->
          <div class="mt-2 mb-2">
            <frequent-pickups-table :filters="filters" :updateList="create.frequentPickup" :currentTab="pickupTab" @editFrequentPickup="openModalEditPickup" @show-details="showPickupDetails" @show-pending-request="openPendingRequestModalBySearch"></frequent-pickups-table>
          </div>
        </b-tab>
        <!-- <b-tab class="no-body" >
          <template #title><feather-icon icon="FileTextIcon"/> Por evaluar</template>
          
          <div class="mt-2" >
            <pickup-filters v-if="pickupTab === 2" :currentTab="pickupTab" @updateFilters="filterPickups"></pickup-filters>
            y aca van otros retiros
          </div>
        </b-tab> -->
      </b-tabs>
    </b-card>
    
    <cutting-schedule-modal-vue></cutting-schedule-modal-vue>
    <info-modal-channel-vue></info-modal-channel-vue>
    <!-- Modal detalles de retiro frecuente -->
    <modal-frequent-pickup-details ref="modalFrequentPickupDetails"></modal-frequent-pickup-details>
    <!-- Modal para crear retiros normales -->
    <modal-create-normal-pickup @updateList="updateNormalPickupsList"></modal-create-normal-pickup>
    <!-- Modal para crear retiros frecuentes -->
    <modal-create-frequent-pickup :editPickupData="currentPickup.editFrequentPickup" :preAproveData="currentPickup.approveFrequentPickup" @updateList="updateFrequentPickupsList" @updateListRequest="updateListRequest"></modal-create-frequent-pickup>
    <!-- Modal listado solicitudes pendientes -->
    <modal-pending-request ref="modalPendingRequest" :updatePendingList="updatePendingList" @updateCountPendingRequest="updateCountPendingRequest" @showModalUpdateRequest="openModalUpdateRequest" @show-details="showPickupDetails"></modal-pending-request>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import * as moment from 'moment'
import PickupFilters from './pickup-filters/PickupFilters.vue'
import TabPickupsService from './tabPickups.service'
import NormalPickupsTable from './normal-pickups-tab/NormalPickupsTable.vue'
import FrequentPickupsTable from './frequent-pickups-tab/FrequentPickupsTable.vue'
import CuttingScheduleModalVue from './cutting-schedule-modal/CuttingScheduleModal.vue'
import InfoModalChannelVue from './components/InfoModalChannel.vue'
import ModalCreateNormalPickup from './normal-pickups-tab/ModalCreatePickup.vue'
import ModalCreateFrequentPickup from './frequent-pickups-tab/ModalCreateFrequentPickup.vue'
import ModalPendingRequest from './frequent-pickups-tab/ModalPendingRequest.vue'
import ModalFrequentPickupDetails from './frequent-pickups-tab/ModalFrequentPickupDetails.vue'
// import ModalApproveOrRejectFrequent from './frequent-pickups-tab/ModalApproveOrRejectFrequent.vue'
export default {
  components: { PickupFilters, NormalPickupsTable, FrequentPickupsTable, CuttingScheduleModalVue, InfoModalChannelVue, ModalCreateNormalPickup, ModalCreateFrequentPickup, ModalPendingRequest, ModalFrequentPickupDetails },
  name: 'tab-pickups',
  data () {
    return {
      pickupTab: null,
      create: {
        btnText: '',
        modalID: 'modalCreateNormalPickup',
        normalPickup: {},
        frequentPickup: {}
      },
      currentPickup: {
        editFrequentPickup: {}
      },
      countPendingRequest: null,
      updatePendingList: 0,
      createBtnText: null,
      filters: {},
      pickups: {
        normal: []
      },
      ROLES : {
        marketplace: 'marketplace',
        ecommerce: 'ecommerce',
        seller: 'seller',
        admin: 'admin',
        superadmin: 'superadmin'
      },
      loading: {
        first: true,
        excel: false,
        evaluateAlert: false
      },
      needEvaluationPickupsFilter: null,
      needEvaluationPickupsCount: 0,
      initialDateRange: {
        start: moment().add(-15, 'days').toDate(),
        end: moment().add(3, 'days').toDate()
      },
      conditionsByRole: {},
      conditionsByPermissions: {},
      tabPickupsService: new TabPickupsService(this)
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession'
    })
  },
  watch: {
    mySession () {
      if (this.mySession.id) this.setInitialData()
    }
  },
  mounted () {
    this.getAllInitialData()
    this.checkIfExistPendingEvaluations()
  },
  methods: {
    getAllInitialData () {
      if (this.mySession.id) this.setInitialData()
    },
    setInitialData () {
      this.loading.first = false
      // Objeto con condiciones según el rol del usuario
      this.conditionsByRole = this.tabPickupsService.conditionsByRole(this.mySession.role)
      this.conditionsByPermissions = this.tabPickupsService.conditionsByPermissions(this.mySession)
      // setTimeout(() => {
      //   this.checkIfExistPendingEvaluations()
      // }, 500)
    },
    filterPickups(filters) {

      // Excluimos algunos filtros sobrantes para la busqueda de retiros frecuentes
      const excludedFilters = ['pickup_date_from', 'pickup_date_to', 'type']
      if (this.pickupTab === 1) {
        excludedFilters.map(excluded_filter => delete filters[excluded_filter])
      }
      if (this.mySession.role !== this.ROLES.admin) {
        this.filters = this.setFiltersByRole(filters)
      } else {
        this.filters = filters
      }
    },
    /** 
     * Función que setea filtros según el rol del usuario
    */
    setFiltersByRole (filters) {
      if (this.mySession.role === this.ROLES.ecommerce || this.mySession.role === this.ROLES.seller) filters = { ...filters, company: this.mySession.shipper.id }
      if (this.mySession.role === this.ROLES.marketplace) filters = { ...filters, marketplace: this.mySession.organization.id }
      return filters
    },
    downloadExcel (type = null) {
      this.loading.excel = true
      this.filters.type = type ? type : this.filters.type
      this.tabPickupsService.callService('downloadPickups', this.filters, null)
        .then(resp => {
          if (resp.data.code === 'OK') {
            window.open(resp.data.url, '_blank')
          } else {
            this.$alert(this.$t('Ocurrió un problema al exportar el archivo'))
          }
        })
        .catch(err => {
          console.error(err)
          this.$alert(this.$t('Ocurrió un problema al exportar el archivo'))
        })
        .finally(end => {
          this.loading.excel = false
        })
    },
    openModalCreatePickup() {
      this.currentPickup.editFrequentPickup = null
      this.currentPickup.approveFrequentPickup = null
      this.$bvModal.show(this.create.modalID)
    },
    openModalEditPickup (pickup) {
      pickup.action = 'edit'
      this.currentPickup.editFrequentPickup = { ...pickup }
      this.currentPickup.approveFrequentPickup = null
      this.$bvModal.show(this.create.modalID)
    },
    openModalUpdateRequest (data) {
      this.currentPickup.editFrequentPickup = { ...data }
      this.currentPickup.approveFrequentPickup = { ...data.last_request.input }
      this.$bvModal.show(this.create.modalID)
    },
    openPendingRequestModal() {
      this.$bvModal.show('modalPendingRequest')
    },
    updateNormalPickupsList(data) {
      this.$bvModal.hide('modalCreateNormalPickup')
      this.create.normalPickup = data
    },
    updateFrequentPickupsList (data) {
      this.$bvModal.hide('modalCreateFrequentPickup')
      this.create.frequentPickup = data
    },
    updateListRequest () {
      this.$bvModal.hide('modalCreateFrequentPickup')
      this.filters = {
        page: 1,
        limit: 20,
        pickup_date_from: this.$options.filters.moment(this.initialDateRange.start, 'YYYY-MM-DD'),
        pickup_date_to: this.$options.filters.moment(this.initialDateRange.end, 'YYYY-MM-DD'),
        type: 'normal,normal_frequent'
      }
      this.updatePendingList++
      this.filterPickups(this.filters)
    },
    // Función encargada de verificar si existen retiros disponibles para evaluar
    checkIfExistPendingEvaluations() {
      this.loading.evaluateAlert = false
      const payload = {
        old: true,
        limit: 1,
        page: 1,
        // pickup_date_from: this.$options.filters.moment(this.initialDateRange.start, 'YYYY-MM-DD'),
        // pickup_date_to: this.$options.filters.moment(this.initialDateRange.end, 'YYYY-MM-DD'),
        need_evaluation: true
      }
      if (this.mySession.role === this.ROLES.ecommerce || this.mySession.role === this.ROLES.seller) payload.company = this.mySession.shipper.id
      if (this.mySession.role === this.ROLES.marketplace) payload.marketplace = this.mySession.organization.id
      this.tabPickupsService.callService('getPickups', payload)
        .then(resp => {
          if (resp.pagination.total_count > 0) {
            this.loading.evaluateAlert = true
            this.needEvaluationPickupsCount = resp.pagination.total_count
          } else {
            this.loading.evaluateAlert = true
          }
        })
        .catch(err => {
          console.error(err)
          this.loading.evaluateAlert = false
        })
    },
    filterNeedEvaluationPickups() {
      if (this.needEvaluationPickupsFilter) {
        this.filters = {
          type: 'normal',
          need_evaluation: true
        }
      } else {
        this.filters = {
          page: 1,
          limit: 20,
          pickup_date_from: this.$options.filters.moment(this.initialDateRange.start, 'YYYY-MM-DD'),
          pickup_date_to: this.$options.filters.moment(this.initialDateRange.end, 'YYYY-MM-DD'),
          type: 'normal,normal_frequent'
        }
      }
    },
    updateCount() {
      this.needEvaluationPickupsCount--
    },
    /**
     * Función para obtener las solicitudes pendientes desde el modal
     */
    updateCountPendingRequest(list) {
      this.countPendingRequest = list.length
      // this.filterPickups(this.filters)
    },
    /**
     * Función que detecta el cambio de tabs de retiros (normal, frequent, need_evaluation)
     * @param {Number} tab 
     */
    handleTabChange(tab) {
      this.pickupTab = tab
      if (this.pickupTab === 0) {
        this.create.btnText = 'Crear retiro diario'
        this.create.modalID = 'modalCreateNormalPickup'
      } else if (this.pickupTab === 1) {
        this.create.btnText = 'Crear retiro frecuente'
        this.create.modalID = 'modalCreateFrequentPickup'
      } else {
        this.create.btnText = 'Crear retiro diario'
        this.create.modalID = 'modalCreateNormalPickup'
      }
    },
    showPickupDetails(pickup) {
      this.$refs.modalFrequentPickupDetails.showPickupDetails(pickup)
    },
    openPendingRequestModalBySearch(pickup) {
      this.$refs.modalPendingRequest.showModal(pickup.identifier)
    }
  }
}
</script>

<style lang='scss'>
.custom-switch-success {
  margin-top: 0.5rem;
  > label {
    font-size: 14px !important;
  }
}
.position-relative {
  position: relative;
}

.count-pending-request {
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 3px 6px;
  font-size: 12px;
  position: absolute;
  top: -5px;
  right: -5px;
}
</style>
