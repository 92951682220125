<template>
  <b-modal id="ModalReviewPickupID" 
    size="lg" centered
    @show="show"
    title="Confirmar Retiro Normal"
    ok-title="Confirmar"
    hide-footer>
    <div v-if="Object.keys(review).length !== 0">
      <!-- <p v-if="review.identifier" title="ID Enviame"><feather-icon icon="NavigationIcon" size="16"/>{{review.identifier}}</p> -->
      <p v-if="review.carrier_pickup_number" title="Numero de reserva / Folio"><feather-icon icon="NavigationIcon" size="16"/> {{review.carrier_pickup_number}}</p>
      <p v-if="review.pickup_date" title="Fecha del retiro"><b-icon icon="calendar3"></b-icon> {{review.pickup_date}}</p>
      <p v-if="review.carrier && review.carrier.name" title="Courier"><b-icon icon="truck"></b-icon> {{review.carrier.name}}</p>
      <p v-if="review.address && review.address.full_address && review.address.place" title="Dirección de retiro"><feather-icon icon="MapPinIcon" size="16"/> {{review.address.full_address}}, {{review.address.place}}</p>
    </div>
    <label class="font-weight-bolder h6">Seleccione una opción para confirmación <span class="text-danger">*</span></label>
    <b-form-group v-slot="{ ariaDescribedby }" plain stacked :state="state">
      <div class="display--grid">
        <b-form-radio v-for="(element, index) in optionsPickup" :key="index" v-model="status" :aria-describedby="ariaDescribedby" :value="element.value">{{element.text}}</b-form-radio>
      </div>
    </b-form-group>
    <div v-if="status === 'done'" class="mt-1">
      <label class="font-weight-bolder h6">Indique en que franja horaria paso el courier <span class="text-danger">*</span> </label>
      <b-form-group v-slot="{ ariaDescribedby }" plain stacked :state="statusDone">
        <b-form-radio class="mb-1" v-for="(element, index) in optionsDone" :key="index" v-model="time_range" :aria-describedby="ariaDescribedby" :value="element.value">{{element.text}}</b-form-radio>
      </b-form-group>
    </div>
    <div v-else-if="status === 'failed'" class="mt-1">
      <label class="font-weight-bolder h6">Indique el motivo <span class="text-danger">*</span> </label>
      <b-form-group v-slot="{ ariaDescribedby }" plain stacked :state="statusFailed">
        <b-form-radio class="mb-1" v-for="(element, index) in optionsFail" :key="index" v-model="reasons" :aria-describedby="ariaDescribedby" :value="element.value">{{element.text}}</b-form-radio>
      </b-form-group>
    </div>

    <template >
      <b-button @click="ok" class="push-right" variant="success" :disabled="disabled.EvaluateButton || !status || (!time_range && !reasons)">
        {{$t('Confirmar')}}
        <i v-if="disabled.EvaluateButton" :class="['fa', 'fa-spinner', 'fa-spin']"/> 
      </b-button>
    </template>
    
  </b-modal>
</template>
<script>
// import BaseServices from '@/store/services/index'
import TabPickupsService from '../tabPickups.service'
import {
  BIcon,
  BIconCalendar3,
  BIconTruck
} from 'bootstrap-vue'
/* eslint-disable vue/no-unused-components */
export default {
  props: ['pickup'],
  components: {BIcon, BIconCalendar3, BIconTruck},
  data() {
    return {
      tabPickupsService: new TabPickupsService(this),
      review: {},
      status: '',
      time_range: '',
      reasons: '',
      disabled: {
        EvaluateButton: false
      },
      optionsPickup: [
        {
          value: 'done',
          text: 'Retirado'
        }, 
        {
          value: 'failed',
          text: 'No Retirado'
        }
      ],
      optionsDone: [
        {
          text: 'Antes de la franja horaria agendada.',
          value: 'before'
        },
        {
          text: 'En la franja horaria agendada (11:30 - 12:30).',
          value: 'within'
        },
        {
          text: 'Después de la franja horaria agendada.',
          value: 'after'
        }
      ],
      optionsFail: [
        {
          text: 'Transportista se presentó pero la carga no estaba lista',
          value: 'not_ready'
        },
        {
          text: 'Transportista no se presentó',
          value: 'absence'
        },
        {
          text: 'Transportista se presentó pero no tenia carga',
          value: 'without_cargo'
        },
        {
          text: 'Transportista se presentó pero la carga muy grande',
          value: 'cargo_too_big'
        },
        {
          text: 'Dirección de retiro estaba incorrecta',
          value: 'wrong_address'
        }, 
        {
          text: 'Bodega estaba cerrada',
          value: 'closed'
        },
        {
          text: 'Se cancela el retiro antes de que pase transportista',
          value: 'deleted'
        }
      ]
    }
  },
  watch: {
    pickup(value) {
      if (Object.keys(value).length !== 0) {
        this.review = value
      }
    },
    status() {
      this.time_range = ''
      this.reasons = ''
    }
  },
  computed: {
    state() {
      return Boolean(this.status)
    },
    statusDone() {
      return Boolean(this.time_range)
    },
    statusFailed() {
      return Boolean(this.reasons)
    }
  },
  methods: {
    show() {
      this.status = ''
      this.time_range = ''
    },
    ok(e) {
      this.disabled.EvaluateButton = true
      e.preventDefault()
      const params = { 
        shipper_id: this.pickup.company.id,
        identifier: this.pickup.identifier
      }
      const queryParams = {
        evaluation: {
          status: this.status
        },
        user: {
          first_name: this.$session.get('cas_user').first_name,
          last_name: this.$session.get('cas_user').last_name,
          email: this.$session.get('cas_user').email,
          role: this.$session.get('cas_user').role
        }
      }
      if (this.time_range) queryParams.evaluation.time_range = this.time_range
      if (this.reasons) queryParams.evaluation.reasons = [this.reasons]
      this.tabPickupsService.callService('postEvaluatePickup', queryParams, params, { fullResponseError: true })
        .then(resp => {
          this.$success('Evaluación actualizada correctamente')
          this.$bvModal.hide('ModalReviewPickupID')
          const pickup_response = {
            ...resp.data,
            identifier: this.pickup.identifier
          }
          if (this.pickup.type.code === 'normal') pickup_response.update_evaluation_count = true
          this.$emit('updatePickupStatus', pickup_response)
        })
        .catch(err => {
          console.error(err)
          this.$alert(err.data)
        })
        .finally(() => this.disabled.EvaluateButton = false)
    }
  }
}
</script>
<style lang="scss">
  .display--grid {
    display: grid;
    grid-template-columns: auto auto;
    width: fit-content;
    gap: 100px;
  }
</style>