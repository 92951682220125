<template lang="">
  <b-modal
    id="infoChannelModalID"
    title="Canales de comunicación"
    size="lg"
    title-class="h1"
    centered
    hide-footer
  >
    <b-card>
      <p>
        Si tienes alguna consulta respecto de tus retiros, necesitas
        modificarlos, no pasaron por tu bodega o no sabes cómo evaluarlos,
        comunícate con nosotros:
      </p>
      <p>
        Para consultas con el área de retiros, contáctenos a
        <span class="font-weight-bolder text-light">retiros@enviame.io</span>
      </p>
      <p>
        También puedes enviar un mensaje o llamar a los siguientes números:
        <span class="font-weight-bolder text-light">+56 9 6769 3525 - +56 9 6769 3494</span>
      </p>
    </b-card>
  </b-modal>
</template>
<script>
export default {}
</script>
