<template>
  <div>
    <b-modal id="modalDisableFrequent"
      ref="modalDisableFrequent"
      size="md"
      :title="$t('Desactivar retiro frecuente')" 
      ok-variant="outline-success"
      ok-only
      :ok-title="$t('Cerrar')"
      centered
      no-close-on-esc
      no-close-on-backdrop
      hide-footer>
        <div>
          <div class="mt-0">
            <form-render ref="formRenderDisableFrequentPickup"
            :fields="fields"
            :form.sync="disableForm"
            @send="disableFrequentPickup">

            </form-render>
          </div>
          <b-button @click="ok" :disabled="loading.disablePickup" class="mt-1 push-right" variant="warning">
            {{$t('Desactivar retiro')}}
            <i v-if="loading.disablePickup" :class="['fa', 'fa-spinner', 'fa-spin']"/> 
          </b-button>
        </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TabPickupsService from '../tabPickups.service'
export default {
  props: ['pickup'],
  data () {
    return { 
      fields: [],
      disableForm: {},
      loading: {
        disablePickup: false
      },
      ROLES: {
        marketplace: { text: 'marketplace', id: 4 },
        ecommerce: { text: 'ecommerce', id: 2 },
        seller: { text: 'seller', id: 2 },
        admin: { text: 'admin', id: 1 },
        superadmin: { text: 'superadmin', id: 1 }
      },
      allDisableReasons: [
        {
          id: 'below_minimum_qty',
          text: 'Cliente no cumple el mínimo'
        },
        {
          id: 'carrier_side_issue',
          text: 'Fallas del courier'
        },
        {
          id: 'no_cargo_delivered',
          text: 'No entrega carga'
        },
        {
          id: 'no_coverage',
          text: 'Sin cobertura'
        },
        {
          id: 'unnecessary',
          text: 'Ya no se necesita el retiro'
        }
      ],
      tabPickupsService: new TabPickupsService(this)
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession'
    })
  },
  watch: {
    mySession () {
      if (this.mySession.id) this.setInitialData()
    }
  },
  mounted () {
    this.getAllInitialData()
    // Se limpia el formulario cada vez que se abre el modal
    this.$nextTick(() => {
      this.$refs.modalDisableFrequent.$on('show', () => {
        delete this.disableForm.reason
      })
    })
  },
  methods: {
    getAllInitialData () {
      if (this.mySession.id) this.setInitialData()
    },
    setInitialData () {
      this.fields = [
        {
          fieldType: 'FieldDatepicker',
          label: 'Fecha fin del programa',
          name: 'date',
          propsVCalendar: { 
            'min-date': new Date()
          },
          disabledDates: { weekdays: [1, 7] },
          validation: 'requiredDate',
          containerClass: 'col-md-12 container-info',
          clearable: false
        },
        {
          fieldType: 'FieldSelect',
          name: 'reason',
          label: 'Razón de desactivación',
          validation: 'required',
          options: this.allDisableReasons,
          containerClass: 'col-md-12 container-info',
          clearable: true
        }
      ]
      this.disableForm.date = new Date()
      this.getDisableFrequentReasons()
    },
    getDisableFrequentReasons () {
      const payload = {
        role: this.ROLES[this.mySession.role].id
      }
      this.tabPickupsService.callService('getDisableFrequentReasons', payload)
        .then(resp => {
          this.fields[this.selectionIndex('reason')].options = Object.entries(resp.data).map(([id, text]) => ({ id, text }))
        })
    },
    /** 
    * Busca el indice de un campo segun su nombre
    */
    selectionIndex(name) {
      return this.fields.findIndex(el => el.name === name)
    },
    ok (e) {
      e.preventDefault()
      this.$refs.formRenderDisableFrequentPickup.checkForm()
    },
    disableFrequentPickup(data) {
      this.loading.disablePickup = true
      const payload = {
        effective_date: this.$options.filters.jsDateToFormat(data.date, 'yyyy-MM-dd'),
        reason_code: data.reason.id
      }
      const params = { 
        shipper_id: this.pickup.company.id,
        identifier: this.pickup.identifier
      }
      this.tabPickupsService.callService('disableFrequent', payload, params, { fullResponseError: true })
        .then(resp => {
          this.$success('Retiro desactivado correctamente')
          const pickup_response = {
            ...resp.data,
            identifier: this.pickup.identifier
          }
          this.$bvModal.hide('modalDisableFrequent')
          this.$emit('updatePickupStatus', pickup_response)
        })
        .catch(error => {
          console.error(error)
          this.$alert(error.data)
        })
        .finally(() => {
          this.loading.disablePickup = false
          this.$bvModal.hide('modalDisableFrequent')
        })
    }
  }
}
</script>

<style>

</style>