<template>
  <b-modal id="modalCreateFrequentPickup"
    :title="modalData.title"
    no-close-on-esc
    no-close-on-backdrop
    centered
    size="lg"
    @show="close"
    hide-footer>
      <div class="mt-0">
        <form-render ref="formRenderCreateFrequentPickup" 
          :fields="fields"
          :key="keyFormRender"
          :form.sync="pickupRequest"
          @send="createOrEditPickup">
            <template #admin_range_time v-if="display.adminRangeTime">
              <div>
                <label class="small-label">Rango horario especial</label>
                <div class="d-flex align-items-start">
                  <b-form-input class="col-md- me-2" v-model="pickupRequest.adminRangeTime" type="text"></b-form-input>
                  <b-button @click="addAdminRangeTime" pill variant="warning" v-b-tooltip.hover :title="$t('Agregar rango')">
                    <feather-icon icon="PlusCircleIcon"/>
                  </b-button>
                </div>
              </div>
            </template>
        </form-render>

        <!-- Alerta para cambios en solicitud de edición -->
        <b-alert class="p-1 col-sm-12 col-md-12 col-lg-12" style="border: 1px solid #f18805; font-size: 12px; color: black;" variant="warning" show v-if="preAproveData && (conditionsByPermissions.is_team_pickup || conditionsByPermissions.frequent_marketplace_supervisor)">
          <div class="d-flex align-items-center">
            <div class="flex-shrink-0 mr-2">
              <feather-icon size="30" icon="AlertTriangleIcon"/>
            </div>
            <div class="flex-grow-1">
              <span class="text-break" style="font-size: 12px; color: gray;">
                {{ alertMessage }}
              </span>
            </div>
          </div>
        </b-alert>

        <b-button v-if="preAproveData && (conditionsByPermissions.is_team_pickup || conditionsByPermissions.frequent_marketplace_supervisor)" @click="backToPendingRequest" variant="warning">
          <feather-icon icon="ArrowLeftIcon"/> Volver al listado
        </b-button>
        <b-button @click="ok" class="push-right" variant="warning" :disabled="disabled.createPickup">
          {{ modalData.btnLabel }}
          <i v-if="loading.createPickup" :class="['fa', 'fa-spinner', 'fa-spin']"/>
        </b-button>
      </div>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import * as moment from 'moment'
import TabPickupsService from '../tabPickups.service'
export default {
  props: ['editPickupData', 'preAproveData'],
  data () {
    return {
      fields: [],
      pickupRequest: {},
      keyFormRender: 0,
      carrierConfig: {},
      modalData: {
        title: 'Crear retiro frecuente',
        btnLabel: 'Crear retiro'
      },
      loading: {
        createPickup: false
      },
      disabled: {
        createPickup: false
      },
      display: {
        adminRangeTime: false
      },
      currentEditSpecialRangeTime: '',
      alertMessage: '',
      isEditForm: false,
      ROLES : {
        marketplace: 'marketplace',
        ecommerce: 'ecommerce',
        seller: 'seller',
        admin: 'admin',
        superadmin: 'superadmin'
      },
      sizePickup: [
        { id: 'xs', text: 'Sobre', content: '20cm x 20cm x 1cm', contentStyles: 'font-size: 12px; color: #BBB', default: true },
        { id: 's', text: 'Pequeño', content: '10cm x 15cm x 5cm', contentStyles: 'font-size: 12px; color: #BBB' },
        { id: 'm', text: 'Mediano', content: '20cm x 30cm x 10cm', contentStyles: 'font-size: 12px; color: #BBB' },
        { id: 'l', text: 'Grande', content: '40cm x 60cm x 30cm', contentStyles: 'font-size: 12px; color: #BBB' },
        { id: 'c', text: 'Más Grande', content: 'Otras medidas', contentStyles: 'font-size: 12px; color: #BBB' }
      ],
      frequentPickupsDays: [
        { label:'Lu', text: 'Lunes', id:'mon', calendar_day_id: 2 },
        { label:'Ma', text: 'Martes', id:'tue', calendar_day_id: 3 },
        { label:'Mi', text: 'Miércoles', id:'wed', calendar_day_id: 4 },
        { label:'Ju', text: 'Jueves', id:'thu', calendar_day_id: 5 },
        { label:'Vi', text: 'Viernes', id:'fri', calendar_day_id: 6 }
      ],
      currentDate: new Date(),
      countriesData: {
        CL: {
          phone_code:56,
          phone_digits:9
        },
        MX: {
          phone_code:52,
          phone_digits:10
        },
        CO: {
          phone_code:57,
          phone_digits:13
        },
        PE: {
          phone_code:51,
          phone_digits:9
        }
      },
      fieldSkipLine: { name: '', useSkeleton: true, skipLine: true, containerClass: 'col-12' },
      dimensionLabel: { name: 'dimensionLine', label: 'Medidas (Alto, Ancho, Largo en cm)', useSkeleton: true, skipLine: true, containerClass: 'col-12' },
      conditionsByPermissions: {},
      tabPickupsService: new TabPickupsService(this)
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession'
    })
  },
  watch: {
    mySession () {
      if (this.mySession.id) this.setInitialData()
    },
    preAproveData: {
      handler(newVal) {
        this.localPreAproveData = { ...newVal }
      },
      deep: true
    },
    editPickupData: {
      handler(newVal) {
        this.isEditForm = !!newVal
        if (this.isEditForm) {
          if (newVal.action === 'edit') {
            this.modalData.title = 'Editar retiro frecuente'
            this.modalData.btnLabel = 'Editar retiro'
          } else if (newVal.action === 'update') {
            this.modalData.title = 'Aceptar solicitud'
            this.modalData.btnLabel = 'Aceptar retiro'
          }
          this.setDataEditPickup({...newVal})
        } else {
          this.modalData.title = 'Crear retiro frecuente'
          this.modalData.btnLabel = 'Crear retiro'
        }
      },
      deep: true
    }
  },
  mounted () {
    this.getAllInitialData()
  },
  methods: {
    getAllInitialData () {
      if (this.mySession.id) this.setInitialData()
    },
    setInitialData () {
      this.fields = [
        {
          fieldType: 'FieldSelect',
          name: 'shipper',
          label: 'Empresa',
          validation: 'required',
          containerClass: 'col-md-6 container-info',
          change: this.onChangeCompany,
          clearable: false,
          searchOnType: {
            fx: this.getShippers,
            nChars: 2,
            debounce: 300,
            allowSearchAsValue: true,
            persistSearch: true
          } 
        },
        {
          fieldType: 'FieldSelect',
          name: 'carrier',
          label: 'Carrier',
          validation: 'required',
          containerClass: 'col-md-6 container-info',
          change: this.onChangeCarrier,
          clearable: false
        },
        {
          fieldType: 'FieldSelect',
          name: 'warehouse',
          dependency: 'carrier',
          label: 'Lugar de retiro/Bodega',
          validation: 'required',
          containerClass: 'col-md-6 container-info',
          // change: this.onChangeWarehouse,
          clearable: false
        },
        {
          fieldType: 'FieldInput',
          name: 'qty',
          type: 'number',
          label: 'Cantidad de bultos',
          validation: 'required',
          containerClass: 'col-md-3 container-info',
          change: this.validateQtyAndWeight
        },
        {
          fieldType: 'FieldInput',
          name: 'weight',
          // dependency: 'qty',
          type: 'number',
          label: 'Peso total en kg',
          validation: 'required',
          containerClass: 'col-md-3 container-info',
          change: this.validateQtyAndWeight
        },
        {
          fieldType: 'FieldRadio',
          name: 'size',
          label: 'Volumen promedio',
          validation: 'required',
          containerClass: 'col-md-12 my-1 border-bottom pb-2',
          change: this.onChangeSize,
          align: 'h',
          customRadio: true,
          optionalSecondLabel: 'Ingresar el volumen unitario promedio de los envíos o el volumen del envío más grande.',
          options: this.sizePickup
        },
        {
          fieldType: 'FieldInput',
          name: 'qty_pallets',
          type: 'number',
          label: 'Cantidad de pallets',
          containerClass: 'col-md-6 container-info'
        },
        {
          fieldType: 'FieldInput',
          name: 'carrier_pickup_number',
          type: 'number',
          label: 'Identificador del courier',
          containerClass: 'col-md-6 container-info'
        },
        // this.fieldSkipLine,
        {
          fieldType: 'FieldCheckbox',
          name: 'pickup_days',
          label: 'Seleccione días',
          validation: 'required',
          align: 'h',
          multiple: true,
          // useAllCheck: true,
          tooltip: {
            text: 'Los días seleccionados condicionarán la fecha de inicio.'
          },
          dependency: 'carrier',
          change: this.onChangePickupsDays,
          options: this.frequentPickupsDays,
          containerClass: 'col-md-6 container-info'
        },
        {
          fieldType: 'FieldDatepicker',
          label: 'Fecha de inicio',
          name: 'start_date',
          tooltip: {
            text: 'La fecha de inicio se ajustará a los días de retiro elegidos. Seleccione fechas dentro de ese rango.'
          },
          propsVCalendar: { 
            'min-date': this.currentDate
          },
          disabledDates: { weekdays: [1, 7] },
          validation: 'requiredDate',
          containerClass: 'col-md-6 container-info'
        },
        {
          fieldType: 'FieldSelect',
          name: 'range_time',
          label: 'Rango horario',
          validation: 'required',
          change: this.onChangeRangeTime,
          containerClass: 'col-md-6 container-info',
          keyFormRender: 0
        },
        // {
        //   fieldType: 'FieldDatepicker',
        //   label: 'Fecha de inicio',
        //   name: 'start_date',
        //   tooltip: {
        //     text: 'La fecha de inicio se ajustará a los días de retiro elegidos. Seleccione fechas dentro de ese rango.'
        //   },
        //   propsVCalendar: { 
        //     'min-date': this.currentDate
        //   },
        //   disabledDates: { weekdays: [1, 7] },
        //   validation: 'requiredDate',
        //   containerClass: 'col-md-6 container-info'
        // },
        { name: 'admin_range_time', useSlot: true, containerClass: 'col-md-6 container-info' },
        this.fieldSkipLine,
        {
          fieldType: 'FieldInput',
          name: 'contact_name',
          label: 'Nombre contacto bodega',
          validation: 'required',
          containerClass: 'col-md-6 container-info'
        },
        {
          fieldType: 'FieldInput',
          name: 'contact_phone',
          dependency: 'shipper',
          type: 'number',
          label: 'Teléfono de contacto',
          containerClass: 'col-md-6 container-info',
          validation: 'required',
          prepend: { text: '+', color: '#BBBBBB' }
        },
        { 
          fieldType: 'FieldTextarea',
          name: 'information',
          label: 'Observación',
          containerClass: 'col-md-12 container-info'
        }
      ]
      this.conditionsByPermissions = this.tabPickupsService.conditionsByPermissions(this.mySession)

      if (this.mySession.role === this.ROLES.ecommerce || this.mySession.role === this.ROLES.seller) {
        // Se saca el campo de shipper del formulario de creacion y se setea el campo con el id del usuario
        this.fields = this.fields.filter(field => !['shipper'].includes(field.name))
        this.pickupRequest.shipper = this.mySession.shipper
        this.onChangeCompany()
      }
      if (this.mySession.role !== this.ROLES.admin) this.fields = this.fields.filter(field => !['carrier_pickup_number'].includes(field.name))
      this.pickupRequest.size = { id: 'xs', text: 'Sobre' }

    },
    // FUNCIONES GET
    getShippers(value) {
      let service = 'getShippers'
      const params = {}
      const payload = {
        search: value,
        extra_fields: 'country'
      }
      if (this.mySession.role === 'marketplace') {
        service = 'getShippersByOrganizationPucon'
        params.organization_id = this.mySession.organization.id
        // payload.extra_fields = 'country'
        // payload.search = value
        // delete payload.name
      }
      return this.tabPickupsService.callService(service, payload, params)
        .then(response => {
          return response.data.map(el => ({...el, text: `${el.id} - ${el.name1 ? el.name1 : el.name}`}))
        })
        .catch(error => console.error(error))
    },
    getCarriers(shipper_id) {
      const queryParams = {
        extra_fields: 'customization,frequent_configuration'
      }
      return this.tabPickupsService.callService('getCarriersDistribution', queryParams, { shipper_id })
        .then(resp => {
          // Se rellena el selector "Carrier" con el listado de carriers
          const carriers = resp.data.map(el => ({ ...el, text: el.name }))
          // this.$refs.formRenderCreateFrequentPickup.setSelectOptions('carrier', carriers)
          this.setSelectOptions('carrier', carriers)
        })
        .catch(error => console.error(error))
    },
    getWarehouses(shipper_id) {
      this.tabPickupsService.callService('getWarehousesApiPlatform', null, { shipper_id })
        .then(resp => {
          const warehouses = resp.data.map(warehouse => ({ ...warehouse, text: `${warehouse.name} - ${warehouse.place_name}` }))
          // this.$refs.formRenderCreateFrequentPickup.setSelectOptions('warehouse', warehouses)
          this.setSelectOptions('warehouse', warehouses)
        })
        .catch(error => console.error(error))
    },
    // FUNCIONES ONCHANGE
    onChangeCompany (modalEdit = true) {
      if (!modalEdit) {
        // Limpiamos los campos correspondientes al cambio de empresa
        delete this.pickupRequest.carrier
        delete this.pickupRequest.warehouse
        delete this.pickupRequest.range_time

        // Se limpia el contact_phone para prevenir errores de codigo de pais
        delete this.pickupRequest.contact_phone
        delete this.pickupRequest.qty
      }

      if (!this.pickupRequest.shipper) {
        this.$refs.formRenderCreateFrequentPickup.setSelectOptions('carrier', [])
        this.$refs.formRenderCreateFrequentPickup.setSelectOptions('warehouse', [])
        this.fields[this.selectionIndex('contact_phone')].prepend = { text: '+', color: '#BBBBBB' }
        delete this.fields[this.selectionIndex('contact_phone')].max
      } else {
        const countryCode = this.pickupRequest.shipper.country.code
        if (this.countriesData[countryCode]) {
          const phoneCode = this.countriesData[countryCode].phone_code
          const phoneDigits = this.countriesData[countryCode].phone_digits
          this.fields[this.selectionIndex('contact_phone')].prepend = { text: `+${phoneCode}` }
          this.fields[this.selectionIndex('contact_phone')].max = phoneDigits
        }
        // Funciones para rellenar selectores
        this.getCarriers(this.pickupRequest.shipper.id)
        this.getWarehouses(this.pickupRequest.shipper.id)
      }
      // this.$refs.formRenderCreateFrequentPickup.updateUIForm()
    },
    onChangeCarrier () {
      // Se borran rangos horarios y bodega
      delete this.pickupRequest.range_time
      delete this.pickupRequest.warehouse
      delete this.pickupRequest.pickup_days
      this.setRangeTimes()
      this.setPickupDays()
    },
    onChangeSize() {
      this.setDimensionFields(this.pickupRequest.size)
    },
    onChangePickupsDays () {
      delete this.pickupRequest.start_date
      const selected_pickups_calendar_id = this.pickupRequest.pickup_days.map(day => day.calendar_day_id)
      const allDays = [1, 2, 3, 4, 5, 6, 7]
      const unselectedDays = allDays.filter(day => !selected_pickups_calendar_id.includes(day))
      // Ajuste de los días del calendario "start_date"
      this.fields[this.selectionIndex('start_date')].disabledDates = { weekdays: selected_pickups_calendar_id.length > 0 ? unselectedDays : [1, 7] }
      // Rangos horarios especiales para dia sabado
      this.setSpecialRangeTime()
      // Se eliminan datos relacionados a admin de retiros 
      if (this.conditionsByPermissions.is_team_pickup && this.conditionsByPermissions.is_admin_team_pickup) {
        delete this.pickupRequest.adminRangeTime
        this.display.adminRangeTime = false
      }
    },
    onChangeRangeTime () {
      this.display.adminRangeTime = false
      if (!this.pickupRequest.range_time) return
      if (!this.pickupRequest.range_time.adminWeekRangeTime) return
      // En caso de que sea un admin de retiros
      this.display.adminRangeTime = true
      delete this.pickupRequest.range_time
      this.currentEditSpecialRangeTime = 'range_time'
      this.keyFormRender++

    },
    onChangeSatRangeTime() {
      this.display.adminRangeTime = false
      if (!this.pickupRequest.saturday_range_time) return
      if (!this.pickupRequest.saturday_range_time.adminSatRangeTime) return
      // En caso de que sea un admin de retiros
      this.display.adminRangeTime = true
      delete this.pickupRequest.saturday_range_time
      this.currentEditSpecialRangeTime = 'saturday_range_time'
      this.keyFormRender++
    },
    // FUNCIONES SET
    setDimensionFields(size) {
      if (size.id === 'c') {
        // Si es "C" se despliegan los nuevos campos (alto, ancho, largo y descripción).
        const customSizeFields = [
          this.dimensionLabel,
          {
            fieldType: 'FieldInput',
            name: 'height',
            type: 'number',
            label: 'Alto',
            validation: 'required',
            containerClass: 'col-md-2 container-info my-1 border-bottom pb-2',
            change: this.checkConditions
          },
          {
            fieldType: 'FieldInput',
            name: 'width',
            type: 'number',
            label: 'Ancho',
            validation: 'required',
            containerClass: 'col-md-2 container-info my-1 border-bottom pb-2',
            change: this.checkConditions
          },
          {
            fieldType: 'FieldInput',
            name: 'length',
            type: 'number',
            label: 'Largo',
            validation: 'required',
            containerClass: 'col-md-2 container-info my-1 border-bottom pb-2',
            change: this.checkConditions
          },
          {
            fieldType: 'FieldTextarea',
            name: 'description',
            label: 'Descripción de los productos',
            validation: 'required',
            containerClass: 'col-md-6 container-info my-1 border-bottom pb-2'
          }
        ]
        this.fields.splice(this.selectionIndex('size') + 1, 0, ...customSizeFields)
      } else {
        // Si no es "C" no se muestra nada y se limpian los campos
        this.fields = this.fields.filter(field => !['height', 'width', 'length', 'description', 'dimensionLine'].includes(field.name))
        delete this.pickupRequest.height
        delete this.pickupRequest.width
        delete this.pickupRequest.length
        delete this.pickupRequest.description
      }

    },
    setRangeTimes() {
      // Se rellena el selector "Rango horario" con los rangos horarios del carrier seleccionado
      // const rangeTime = this.pickupRequest.carrier.normal_configuration.range_time.map(el => ({ ...el, text: el.label }))

      const rangeTime = this.pickupRequest.carrier.frequent_configuration.range_time.map((el, index) => ({ 
        ...el,
        id: index,
        text: el.label
      }))
      // this.$refs.formRenderCreateFrequentPickup.setSelectOptions('range_time', rangeTime)
      this.setSelectOptions('range_time', rangeTime)
      // En caso de ser admin de retiros se edita el select de range_time
      if (this.conditionsByPermissions.is_team_pickup && this.conditionsByPermissions.is_admin_team_pickup) this.changeToCustomSelect('range_time', rangeTime)
    },
    setPickupDays() {
      // Seteo de los días de retiro
      this.fields[this.selectionIndex('pickup_days')].options = this.parseDays(this.pickupRequest.carrier.frequent_configuration.days)
    },
    setSpecialRangeTime() {
      // Verifica que exista el ddia "sat" en el array de pickup_days
      const isSat = this.pickupRequest.pickup_days.find(day => day.id === 'sat')
      const specialFieldRangeTimeIndex = this.fields.findIndex(field => field.name === 'saturday_range_time')
      const frequentConfiguration = this.pickupRequest.carrier.frequent_configuration
      // Verifica que existan rangos horarios para el dia "sat"
      const hasSatRangeTime = Array.isArray(frequentConfiguration?.config_days?.sat?.range_time)

      if (isSat && hasSatRangeTime && specialFieldRangeTimeIndex === -1) {
        const sat_range_time = frequentConfiguration.config_days.sat.range_time.map((range, index) => ({
          ...range,
          id: index,
          text: range.label
        }))

        const specialFieldRangeTime = {
          fieldType: 'FieldSelect',
          name: 'saturday_range_time',
          label: 'Rango horario día sabado',
          validation: 'required',
          change: this.onChangeSatRangeTime,
          options: sat_range_time,
          containerClass: 'col-md-6 container-info'
        }
        this.fields.splice(this.selectionIndex('range_time') + 1, 0, specialFieldRangeTime)
        if (this.conditionsByPermissions.is_team_pickup && this.conditionsByPermissions.is_admin_team_pickup) this.changeToCustomSelect('saturday_range_time', sat_range_time)
      } else if ((!isSat || !hasSatRangeTime) && specialFieldRangeTimeIndex !== -1) {
        this.fields = this.fields.filter(field => !['saturday_range_time'].includes(field.name))
        delete this.pickupRequest.saturday_range_time
      }
    },
    /**
     * Función que se encarga de parsear los días de un string a un arreglo de objetos.
     * @param {String} days días (abreviado) separados por coma ejm: "lun,mar,mie"
     * @returns {Array.<Object>} retorna un arreglo de objetos con los días.
     */
    parseDays(days) {
      const parsed_days = []
      days.split(',').forEach(day => {
        switch (day) {
        case 'lu':
          parsed_days.push({ label: 'Lu', text: 'Lunes', id:'mon', calendar_day_id: 2 })
          break
        case 'ma':
          parsed_days.push({ label: 'Ma', text: 'Martes', id:'tue', calendar_day_id: 3 })
          break
        case 'mi':
          parsed_days.push({ label: 'Mi', text: 'Miércoles', id:'wed', calendar_day_id: 4 })
          break
        case 'ju':
          parsed_days.push({ label: 'Ju', text: 'Jueves', id:'thu', calendar_day_id: 5 })
          break
        case 'vi':
          parsed_days.push({ label: 'Vi', text: 'Viernes', id:'fri', calendar_day_id: 6 })
          break
        case 'sa':
          parsed_days.push({ label: 'Sa', text: 'Sabado', id:'sat', calendar_day_id: 7 })
          break
        case 'do':
          parsed_days.push({ label: 'Do', text: 'Domingo', id:'sun', calendar_day_id: 1 })
          break
        }
      })
      return parsed_days
    },
    /** 
    * Busca el indice de un campo segun su nombre
    */
    selectionIndex(name) {
      return this.fields.findIndex(el => el.name === name)
    },
    disableForm(disable) {
      this.fields.map(field => field.disabled = disable)
    },
    close () {
      this.pickupRequest = {}
      this.fields[this.selectionIndex('contact_phone')].prepend = { text: '+', color: '#BBBBBB' }
      delete this.fields[this.selectionIndex('contact_phone')].max

      this.pickupRequest.size = { id: 'xs', text: 'Sobre' }
      this.fields = this.fields.filter(field => !['height', 'width', 'length', 'description', 'dimensionLine', 'saturday_range_time'].includes(field.name))
      // this.fields = this.fields.filter(field => !['saturday_range_time'].includes(field.name))

      this.fields[this.selectionIndex('weight')].msgError = ''
      this.fields[this.selectionIndex('qty')].msgError = ''

      if (this.mySession.role === this.ROLES.ecommerce || this.mySession.role === this.ROLES.seller) this.setInitialData()
      // this.$refs.formRenderCreateFrequentPickup.updateUIForm()
      this.display.adminRangeTime = false
      delete this.pickupRequest.adminRangeTime
      this.disableForm(false)
    },
    ok (e) {
      e.preventDefault()
      this.$refs.formRenderCreateFrequentPickup.checkForm()
    },
    validateQtyAndWeight() {
      this.fields[this.selectionIndex('weight')].msgError = ''
      this.fields[this.selectionIndex('qty')].msgError = ''
      this.disabled.createPickup = false
      // Validar peso mayor a 0.1
      if (Number(this.pickupRequest.weight) < 0.1) {
        this.fields[this.selectionIndex('weight')].msgError = 'El valor minimo es 0.1'
        this.disabled.createPickup = true
      }
      // Validar el qty mayor a 1
      if (Number(this.pickupRequest.qty) < 1) {
        this.fields[this.selectionIndex('qty')].msgError = 'El valor minimo es 1'
        this.disabled.createPickup = true
      }
    },
    /**
     * Actualiza las opciones de un campo del tipo FieldSelect
     * @param {string} name Nombre del campo a actualizar sus opciones
     * @param {any[]} options Opciones a actualizar
     */
    setSelectOptions(name, options) {
      const index = this.fields.findIndex(el => el.name === name)
      this.fields[index].options = options
      if (this.fields[index].keyFormRender !== undefined) this.fields[index].keyFormRender++
      else this.keyFormRender++
    },
    /**
     * Funcion que se encarga de identificar los cambios de una edición de retiro (retiro original vs cambios en el formulario).
     *
     * @param {Object} formPickup objeto con los datos del formulario
     * @param {Object} originalPickup objeto con los datos del retiro original
     * @returns {Object} Retorna un objeto con los cambios que se han hecho en el objeto
     */
    getChangesFromPickup(formPickup, originalPickup) {
      let changes = {}

      const {
        qty: qty_form,
        weight: weight_form,
        size: size_form,
        description: description_form,
        qty_pallets: qty_pallets_form,
        carrier_pickup_number: carrier_pickup_number_form,
        pickup_days: pickups_days_form_obj,
        range_time: range_time_form,
        contact_name: contact_name_form,
        contact_phone: contact_phone_form,
        information: information_form,
        custom_size: custom_size_form,
        warehouse: warehouse_form,
        start_date: start_date_form
      } = formPickup

      const {
        qty: qty_original,
        weight: weight_original,
        size: size_original,
        description: description_original,
        qty_pallets: qty_pallets_original,
        carrier_pickup_number: carrier_pickup_number_original,
        pickup_days: pickups_days_original,
        range_time: range_time_original,
        contact: { name: contact_name_original, phone: contact_phone_original },
        information: information_original,
        custom_size: custom_size_original,
        warehouse: warehouse_original,
        last_request
      } = originalPickup
      const start_date_original = last_request.effective_date

      // Con todo esto se ordenana los dias de retiro para poder compararlos
      const daysOfWeek = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']
      let pickups_days_form = []
      if (pickups_days_form_obj) {
        pickups_days_form = pickups_days_form_obj.map(day => day.id).sort((a, b) => daysOfWeek.indexOf(a) - daysOfWeek.indexOf(b))
        // Hacer una copia de la matriz original antes de ordenarla sino activara el watch de editPickupData
        const pickups_days_original_copy = [...pickups_days_original]
        pickups_days_original_copy.sort((a, b) => daysOfWeek.indexOf(a) - daysOfWeek.indexOf(b))
      }
      const pickups_days_original_sorted = [...pickups_days_original].sort((a, b) => daysOfWeek.indexOf(a) - daysOfWeek.indexOf(b))

      if (qty_form !== qty_original) {
        changes.qty = qty_form
      }

      if (weight_form !== weight_original) {
        changes.weight = weight_form
      }

      if (size_form.id !== size_original) {
        changes.size = size_form.id
      }

      if (description_form !== description_original) {
        changes.description = description_form
      }

      if (qty_pallets_form !== qty_pallets_original) {
        changes.qty_pallets = qty_pallets_form
      }

      if (carrier_pickup_number_form !== carrier_pickup_number_original) {
        changes.carrier_pickup_number = carrier_pickup_number_form
      }

      if (JSON.stringify(pickups_days_form) !== JSON.stringify(pickups_days_original_sorted)) {
        changes.pickup_days = pickups_days_form
      }

      if (range_time_form.label !== range_time_original) {
        changes.range_time = range_time_form.label
      }

      if (contact_name_form !== contact_name_original) {
        changes.contact_name = contact_name_form
      }

      if (contact_phone_form !== contact_phone_original) {
        changes.contact_phone = contact_phone_form
      }

      if (information_form !== information_original) {
        changes.information = information_form
      }

      if (custom_size_form?.height !== custom_size_original?.height) {
        changes.height = custom_size_form.height
      }

      if (custom_size_form?.width !== custom_size_original?.width) {
        changes.width = custom_size_form.width
      }

      if (custom_size_form?.length !== custom_size_original?.length) {
        changes.length = custom_size_form.length
      }

      if (warehouse_form?.id !== warehouse_original?.id) {
        changes.warehouse_code = warehouse_form?.id
      }

      if (moment(start_date_form).format('YYYY-MM-DD') !== moment(start_date_original).format('YYYY-MM-DD')) {
        changes.start_date = moment(start_date_form).format('YYYY-MM-DD')
      }

      // Rango horario dia sabado
      if (formPickup.saturday_range_time) {
        if (formPickup.saturday_range_time.label !== originalPickup.range_time_exceptions.sat) {
          changes.range_time_exceptions = {
            sat: formPickup.saturday_range_time.label
          }
        }
      }

      // Filtrar campos con valores undefined o null
      changes = Object.fromEntries(Object.entries(changes).filter(([_, v]) => v !== null && v !== undefined))
      return changes
    },
    backToPendingRequest () {
      this.$bvModal.show('modalPendingRequest')
      this.$bvModal.hide('modalCreateFrequentPickup')
    },
    createOrEditPickup() {
      // Dependiendo de dodne se activa el modal se creara, editara o se aceptara solicitud
      if (this.isEditForm) this.editPickup()
      else this.createPickup()
    },
    editPickup() {
      this.loading.createPickup = true
      this.disabled.createPickup = true
      const { first_name, last_name, email, role } = this.mySession
      const { shipper } = this.pickupRequest
      // Se construye el payload base con el user
      let payload = {
        user: {
          first_name,
          last_name,
          email,
          role
        }
      }

      const changes = this.getChangesFromPickup(this.pickupRequest, this.editPickupData)

      let service = ''
      let params = { shipper_id: shipper.id, identifier: this.editPickupData.identifier }
      if (this.conditionsByPermissions.is_team_pickup && !this.preAproveData) {
        // Si es del equipo de retiros se envia cada campo editado
        service = 'directUpdatePickup'
        payload = { ...payload, ...changes, company: shipper }
      } else if (!this.conditionsByPermissions.is_team_pickup && !this.preAproveData) {
        // En caso de que no sea parte del equipo de retiros el payload se encapsula en la prop "data"
        service = 'createEditRequest'
        payload = { ...payload, data: changes, type: 'update' }
      } else if ((this.conditionsByPermissions.is_team_pickup || this.conditionsByPermissions.frequent_marketplace_supervisor) && this.preAproveData) {
        // Si preAproveData existe se esta aceptando una solicitud
        service = 'approveOrRejectFrequentPickup'
        payload = { ...payload, data: changes, type: 'approve' }
        params = { ...params, requests_id: this.editPickupData.last_request.id }
      }

      this.tabPickupsService.callService(service, payload, params, { fullResponseError: true })
        .then(resp => {
          const title = 'Edición de retiro Ok!'
          this.$success(title)
          this.$emit('updateListRequest', resp.data)
        })
        .catch(error => {
          console.error(error)
          this.$alert(error.data)
        })
        .finally(() => {
          this.loading.createPickup = false
          this.disabled.createPickup = false
        })
    },
    createPickup() {
      this.loading.createPickup = true
      this.disabled.createPickup = true
      const { first_name, last_name, email, role } = this.mySession
      const { 
        shipper, 
        carrier, 
        warehouse, 
        qty, 
        weight, 
        size, 
        pickup_days,
        start_date,
        range_time, 
        contact_name, 
        contact_phone 
      } = this.pickupRequest
      // Se construye el payload base
      const payload = {
        source: 'platform',
        carrier_code: carrier.code,
        warehouse_code: warehouse.id,
        qty: Number(qty),
        weight: Number(weight),
        pickup_days: pickup_days.map(day => day.id),
        start_date: moment(start_date).format('YYYY-MM-DD'),
        contact_name,
        contact_phone,
        range_time: range_time.label,
        size: size.id,
        user: {
          first_name,
          last_name,
          email,
          role
        }
      }
      // Si el size es "c" se agrega custom size
      if (this.pickupRequest.size.id === 'c') {
        payload.height = this.pickupRequest.height
        payload.width = this.pickupRequest.width
        payload.length = this.pickupRequest.length
        payload.description = this.pickupRequest.description
      }
      // Cantidad de pallets
      if (this.pickupRequest.qty_pallets) {
        payload.qty_pallets = this.pickupRequest.qty_pallets
      }
      // Identificador del carrier
      if (this.pickupRequest.carrier_pickup_number) {
        payload.carrier_pickup_number = this.pickupRequest.carrier_pickup_number
      }
      // Información del retiro
      if (this.pickupRequest.information) {
        payload.information = this.pickupRequest.information
      }
      // Rango horario dia sabado
      if (this.pickupRequest.saturday_range_time) {
        payload.range_time_exceptions = {
          sat: this.pickupRequest.saturday_range_time.label
        }
      }

      this.tabPickupsService.callService('createFrequentPickup', payload, { shipper_id: shipper.id }, { fullResponseError: true })
        .then(resp => {
          // const timeLimit = resp.data.carrier.config.time_limit
          // this.pickupRequest.carrier
          // const text = `<p>Recuerde que para retiros en el mismo día, se tomarán únicamente los envíos en estado <strong>Listo para Despacho - Impreso</strong> agendados <strong>antes de las ${timeLimit} hs.</strong></p>`
          const title = 'Creación de retiro Ok!'
          this.$success(title)
          this.$emit('updateList', resp.data)
        })
        .catch(error => {
          console.error(error)
          this.$alert(error.data)
        })
        .finally(() => {
          this.loading.createPickup = false
          this.disabled.createPickup = false
        })
    },
    mapInputData (inputs, original_data) {
      // if (inputs.range_time) {
      //   inputs.range_time = inputs.range_time.label
      // }
      if (inputs.contact_name || inputs.contact_phone) {
        inputs.contact = {
          ...original_data.contact
        }
        if (inputs.contact_name) inputs.contact.name = inputs.contact_name
        if (inputs.contact_phone) inputs.contact.phone = inputs.contact_phone
      }
      return inputs
    },
    setDataEditPickup(editPickupData) {
      if (!!this.fields[this.selectionIndex('shipper')]) this.fields[this.selectionIndex('shipper')].disabled = true
      this.fields[this.selectionIndex('carrier')].disabled = true
      this.fields[this.selectionIndex('warehouse')].disabled = true

      // En caso de que se abra el modal desde solicitudes pendientes (action === 'update')
      if (editPickupData.action === 'update') {
        const preAproveData = this.mapInputData(this.localPreAproveData, editPickupData)
        // Se cosntruye alerta para mostrar campos que cambiaron
        this.buildPreAproveAlert(preAproveData, editPickupData)
        Object.assign(editPickupData, preAproveData)
        if (this.conditionsByPermissions.frequent_marketplace_supervisor) this.disableForm(true)
      }

      const { company, carrier, warehouse, qty, weight, pickup_days, range_time, start_date, contact, information, last_request } = editPickupData
      this.pickupRequest = {
        shipper: { ...company, text: company.name },
        carrier: { ...carrier, text: carrier.name },
        warehouse: { ...warehouse, text: warehouse.name },
        qty,
        weight,
        size: this.sizePickup.filter(size => size.id === editPickupData.size)[0],
        qty_pallets: editPickupData.qty_pallets,
        carrier_pickup_number: editPickupData.carrier_pickup_number,
        pickup_days: this.frequentPickupsDays.filter(day => pickup_days.includes(day.id)),
        range_time: { text: range_time, label: range_time },
        contact_name: contact.name,
        contact_phone: contact.phone,
        information
      }
      // En caso de que el size sea 'c'
      if (editPickupData.size === 'c') {
        const dimensionArray = editPickupData.dimensions.split(' x ').map(dim => parseInt(dim))
        if (dimensionArray.length === 3) {
          this.pickupRequest.height = dimensionArray[0]
          this.pickupRequest.width = dimensionArray[1]
          this.pickupRequest.length = dimensionArray[2]
          this.pickupRequest.description = editPickupData.description
        }
      }
      this.onChangeSize()
      // Se sete el start_date
      this.onChangePickupsDays()

      // Seteo de rangos horarios dia sabado
      this.frequentPickupsDays = this.frequentPickupsDays.filter(day => !['sat'].includes(day.id))
      if (editPickupData.range_time_exceptions?.sat) {
        const sat_tange_time = editPickupData.range_time_exceptions.sat
        this.frequentPickupsDays.push({ label: 'Sa', text: 'Sabado', id:'sat', calendar_day_id: 7 })
        this.pickupRequest.pickup_days = this.frequentPickupsDays.filter(day => pickup_days.includes(day.id))
        this.pickupRequest.saturday_range_time = { text: sat_tange_time, label: sat_tange_time }
        this.fields[this.selectionIndex('pickup_days')].options = this.frequentPickupsDays
      } else {
        // this.frequentPickupsDays = this.frequentPickupsDays.filter(day => !['sat'].includes(day.id))
        this.pickupRequest.pickup_days = this.frequentPickupsDays.filter(day => pickup_days.includes(day.id))
        this.fields[this.selectionIndex('pickup_days')].options = this.frequentPickupsDays
      }

      if (last_request?.effective_date) this.pickupRequest.start_date = moment(last_request.effective_date, 'YYYY-MM-DD')

      const arrServices = [
        { 
          name: 'getShipper',
          params: { shipper_id: company.id }
        },
        { 
          name: 'getCarriersDistribution',
          queryParams: { extra_fields: 'customization,frequent_configuration' },
          params: { shipper_id: company.id }
        }
      ]
      if (this.mySession.role !== this.ROLES.admin) arrServices.shift()
      this.tabPickupsService.callMultipleServices(arrServices, true)
        .then(resp => {
          const selectedCarrier = resp.getCarriersDistribution.data.filter(carrierDistribution => carrierDistribution.code === editPickupData.carrier.code)[0]
          this.pickupRequest.carrier = { ...selectedCarrier, text: selectedCarrier.name }
          this.setRangeTimes()
          if (editPickupData.range_time_exceptions?.sat) {
            this.setSpecialRangeTime() 
            if (editPickupData.action === 'update') {
              if (this.conditionsByPermissions.frequent_marketplace_supervisor) this.disableForm(true)
            }
          }

          if (this.mySession.role === this.ROLES.admin || this.mySession.role === this.ROLES.superadmin) {
            this.pickupRequest.shipper = { ...company, country: resp.getShipper.data.country, text: company.name }
            this.onChangeCompany(true)
          }
        })
        .catch(error => console.error(error))
    },
    buildPreAproveAlert (preAproveData, editPickupData) {
      // Se obtienen las key de ambos obj para saber cuales se repiten
      const edited_keys = Object.keys(preAproveData)
      const original_keys = Object.keys(editPickupData)
      const repeatedKeys = original_keys.filter(key => edited_keys.includes(key))
      // Keys anidads
      const nestedFieldsMapping = {
        contact: ['contact_name', 'contact_phone']
      }

      // Función para mapear el texto del campo "size"
      const getSizeText = (sizeId) => {
        const size = this.sizePickup.find(s => s.id === sizeId)
        return size ? size.text : sizeId
      }

      // Función para excluir valores nulos o undefined
      const handleNullOrUndefined = (value) => {
        return value !== null ? value : '*Campo vacío*'
      }

      // Función para mapear los días a texto
      const mapDaysToText = (daysArray) => {
        if (daysArray.includes('sat')) this.frequentPickupsDays.push({ label: 'Sa', text: 'Sabado', id:'sat', calendar_day_id: 7 })
        return daysArray.map(dayId => {
          const day = this.frequentPickupsDays.find(d => d.id === dayId)
          return day ? day.text : dayId
        }).join(', ')
      }
      // Obtener los cambios
      const changedFields = this.fields.reduce((acc, field) => {
        const [topLevelKey, nestedKey] = field.name.split('_')
        if (repeatedKeys.includes(topLevelKey)) {
          if (nestedFieldsMapping[topLevelKey] && nestedKey) {
            // Para keys anidads
            acc.push({
              label: field.label,
              old_value: handleNullOrUndefined(editPickupData[topLevelKey]?.[nestedKey]),
              edited_value: handleNullOrUndefined(preAproveData[topLevelKey]?.[nestedKey])
            })
          } else if (!nestedKey) {
            // Para keys de nivel superior
            let oldValue = handleNullOrUndefined(editPickupData[topLevelKey])
            let editedValue = handleNullOrUndefined(preAproveData[topLevelKey])
            
            // Mapear tamaño a texto si es el campo 'size'
            if (topLevelKey === 'size') {
              oldValue = getSizeText(oldValue)
              editedValue = getSizeText(editedValue)
            }

            // Mapear días a texto si es el campo 'pickup_days'
            if (topLevelKey === 'pickup_days') {
              oldValue = mapDaysToText(oldValue)
              editedValue = mapDaysToText(editedValue)
            }
            
            acc.push({
              label: field.label,
              old_value: oldValue,
              edited_value: editedValue
            })
          }
        } else if (field.name in preAproveData && field.name in editPickupData) {
          // Para propiedades de nivel superior no mapeadas (sin '_')
          let oldValue = handleNullOrUndefined(editPickupData[field.name])
          let editedValue = handleNullOrUndefined(preAproveData[field.name])
          
          // Mapear tamaño a texto si es el campo 'size'
          if (field.name === 'size') {
            oldValue = getSizeText(oldValue)
            editedValue = getSizeText(editedValue)
          }

          // Mapear días a texto si es el campo 'pickup_days'
          if (field.name === 'pickup_days') {
            oldValue = mapDaysToText(oldValue)
            editedValue = mapDaysToText(editedValue)
          }

          acc.push({
            label: field.label,
            old_value: oldValue, 
            edited_value: editedValue
          })
        }
        return acc
      }, [])

      if (repeatedKeys.includes('range_time_exceptions')) {
        changedFields.push({
          label: 'Rango horario día sabado',
          old_value: handleNullOrUndefined(editPickupData.range_time_exceptions.sat), 
          edited_value: handleNullOrUndefined(preAproveData.range_time_exceptions.sat)
        })
      }
      const changesCount = changedFields.length
      this.alertMessage = `Ha efectuado ${changesCount} ${changesCount === 1 ? 'modificación' : 'modificaciones'}:`
      changedFields.forEach(change => {
        this.alertMessage += ` ${change.label} ${change.edited_value} (antes ${change.old_value});`
      })
    },
    /**
     * Cambia de select normal a un custom select
     */
    changeToCustomSelect(field, options) {
      const specialOption = { text: 'Añadir otro rango', id: options.length }
      if (field === 'range_time') specialOption.adminWeekRangeTime = true
      else specialOption.adminSatRangeTime = true
      options.unshift(specialOption)
      this.fields[this.selectionIndex(field)].options = options
    },
    addAdminRangeTime() {
      if (!this.pickupRequest.adminRangeTime) return
      let input = this.pickupRequest.adminRangeTime.trim()

      // Corregir el formato
      const regex = /^(\d{2}:\d{2})\s*-\s*(\d{2}:\d{2})$/
      const match = input.match(regex)

      if (match) {
        input = `${match[1]} - ${match[2]}`
        const options = this.fields[this.selectionIndex(this.currentEditSpecialRangeTime)].options
        const specialRangeTime = { text: input, label: input, id: options.length  }
        options.push(specialRangeTime)
        this.$success(`Nuevo rango horario añadido: ${specialRangeTime.label}`)
        delete this.pickupRequest.adminRangeTime
        this.keyFormRender++
      } else {
        this.$alert('Formato inválido. Use el formato HH:mm - HH:mm.')
      }
      // this.display.adminRangeTime = false
      // delete this.pickupRequest.adminRangeTime
    }
  }
}
</script>

<style scoped>
  .small-label {
    font-size: 0.85rem;
    margin-bottom: 0.25rem;
    display: block;
  }
</style>